$color-nav-link: #f0f0f0;
$primary: #0f0f0f;
$secondary: #b6b6b6;
$tertiary: #1e1e1e;
$quaternary: #363636;
$white: #f6f6f6;
$grey: #a1a1a1;
$green: #53AE32;
$mediumGrey: #5d5d5d;

$huge-font: 60px;
$bigger-font: 50px;
$mbigger-font: 45px;
$big-font: 35px;
$large-font: 30px;
$slarge-font: 25px;
$mediuml-font: 20px;
$medium-font: 17px;
$small-font: 15px;
$smaller-font: 13px;
$xsmall-font: 12px;

$navbar-menu-icon-height: 18px;
$navbar-link-size: 15px;

$navbar-height: 9rem;
$lng-navbar-height: 3rem;
$horizontal-padding: 4rem;
$vertical-padding: 2rem;
$min-content-height: 80vh;

$radius: 10px;

$font: 'Roboto';
$font-bold: 'Roboto-Bold';
$font-semibold: 'Roboto-Medium';
$font-RMedium: 'Raleway-Medium';
$font-bolder: 'Roboto-Black';
$font-medium: 'Roboto-Medium';

$small: 380px;
$lsmall: 430px;
$smedium: 600px;
$medium: 770px;
$lmedium: 850px;
$large: 1000px;
$mlarge: 1300px;
$larger: 1500px;


