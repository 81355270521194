@import '../variables.scss';
@import '../navbar/navbar.style.scss';

.enquiryModal {
    height: 80%;
    .productRow {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        max-width: 900px;
        margin-top: 1rem;
        margin-bottom: 1.5rem;


        .productLeft {
            display: flex;
            flex-direction: row;
            width: 100%;

            .productImage {
                .noImgBg {
                    background-color: $grey;
                    max-width: 80px;
                    max-height: 100px;
                    height: 100%;
                    z-index: 10;
                }

                .imgBackground {
                    height: 60px;
                    width: 50px;
                    border-radius: 10px;
                    /* min-height: 90px; */
                    /* max-width: 390px; */
                    /* max-height: 250px; */
                    background-color: white;
                    overflow: hidden;
                    padding: 10px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;

                    img {
                        width: 100%;
                        /* height: 100%; */
                        /* border-radius: 10px; */
                        max-width: 100px;
                        object-fit: cover;
                        z-index: 9;
                        max-width: 100px;
                        position: relative;
                    }
                }
            }

            .leftContent {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                margin-left: 30px;


                .productTitle {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    color: $white;


                }

                .productPrice {
                    margin-right: 1rem;
                    margin-top: 1rem;

                    .outOfStockPrice{
                        color: $grey;
                    }

                    .paragraph {
                        font-family: 'Roboto-bold';
                    }
                }

                


            }

        }

        .productRight {
            width: 60%;
            display: flex;
            flex-direction: row;
            align-items: flex-end;
            justify-content: flex-end;

            .rightContent {
                display: flex;
                flex-direction: row;
                align-items: center;

                .productRemove {
                    .paragraph {
                        color: #d21503;

                        &:hover {
                            cursor: pointer;
                        }
                    }
                }

                .productQuantity {
                    margin-right: 1rem;

                    input {
                        padding: 5px;
                        width: 40px;
                        background-color: white;
                        border: 0;
                        border-radius: 3px;
                        outline: none;
                    }
                }
            }
        }




    }
    .totalPriceEnquiry{
        margin-bottom: 1rem;
        font-family: 'Roboto-bold' !important;
        color: $white;
    }
}