@import '../variables.scss';

.container {
  margin-left: $horizontal-padding;
  margin-right: $horizontal-padding;
  padding-bottom: $horizontal-padding;
}

@media screen and (max-width: $medium) {
  .container {
    padding-left: $vertical-padding !important;
    padding-right: $vertical-padding !important;
    margin-left: $horizontal-padding * 0.5;
    margin-right: $horizontal-padding * 0.5;
  }
}

@media screen and (max-width: $small) {
  .container {
    padding-left: $horizontal-padding * 0.25 !important;
    padding-right: $horizontal-padding * 0.25 !important;
  }
}
@media screen and (max-width: 300px) {
  .container {
    padding-left: $horizontal-padding * 0.2;
    padding-right: $horizontal-padding * 0.2;
  }
}
