@import '../../components/variables.scss';

.chargers {
  display: flex;
  margin-bottom: auto;
  min-height: $min-content-height;
  align-content: flex-start;
  width: 100%;
  justify-content: flex-start;
  flex-direction: column;
  padding-top: $navbar-height;
  .title {
    margin-top: $horizontal-padding;
  }

  .categoryContainer {
    display: flex;
    flex-wrap: wrap;
    margin-top: $vertical-padding + 2;
    margin-bottom: $vertical-padding;
    .button:not(last-child) {
      margin-right: 1rem;
    }
  }
  .productCardContainer {
    margin-bottom: $vertical-padding;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: $vertical-padding;
    justify-items: center;
  }
  .filterContainer {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: $vertical-padding;
    .button:not(last-child) {
      margin-right: 1rem;
    }
    .button {
      &:hover {
        transform: none;
      }
      &:hover > img {
        transform: rotate(180deg);
      }
      img {
        margin-left: 0.5rem;
        transition: transform 0.35s cubic-bezier(0.175, 0.885, 0.32, 1.275);
      }
    }
    .dropdownLink {
      position: relative;

      transition: height 0.75s cubic-bezier(0.175, 0.885, 0.32, 1.275);
      &:hover > .dropdown {
        height: auto;
        transition: height 0.75s cubic-bezier(0.175, 0.885, 0.32, 1.275);
        display: block;
      }
    }
    .dropdown::before {
      position: absolute;
      top: -20px;
      left: 0;
      width: 100%;
      height: 20px;
      content: '';
      display: block;
      z-index: 1;
    }
    .dropdown {
      // display: none;
      z-index: -10;
      position: absolute;
      min-width: 10rem;
      box-shadow: 0 10px 15px -3px rgba(46, 41, 51, 0.08), 0 4px 6px -2px rgba(71, 63, 79, 0.16);
      font-size: 0.875rem;
      min-width: 14rem;
      list-style: none;
      background-color: $white;
      border-radius: 17px;
      height: 0;

      padding: 1.5rem 2rem;
      opacity: 1;
      transition: height 0.75s cubic-bezier(0.175, 0.885, 0.32, 1.275);

      width: 100%;
      height: 100%;
      position: absolute;
      top: 30px;
      left: 0;

      .menu-items > a {
        margin-left: 0rem !important;
        margin-right: 0rem !important;
        margin-bottom: 10rem !important;
      }
      a:hover {
        color: initial;
        cursor: pointer;
      }
    }
    .dropdownLink:hover .dropdown {
      // display: block;
      height: auto;
      z-index: 2000;
    }
  }

  .twoItemsContainer {
    margin-top: $horizontal-padding;
    .title {
      margin-bottom: $horizontal-padding;
    }
    p > span {
      font-family: $font-bolder;
      color: $green;
    }
    .paragraph {
      text-align: left;
      line-height: 200%;
    }
  }

  .productCardContainer {
    margin-bottom: $vertical-padding;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: $vertical-padding;
  }

  @media screen and (max-width: $larger) {
    .productCardContainer {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: $vertical-padding;
    }
  }
  @media screen and (max-width: $large) {
    .productCardContainer {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: $vertical-padding;
    }
  }
  @media screen and (max-width: $lmedium) {
    .categoryContainer {
      .button {
        min-width: 0 !important;
        margin-top: 0.5rem !important;
        margin-left: 0 !important;
      }
    }
    .productCardContainer {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: $vertical-padding;
      justify-items: center;
    }
  }
  @media screen and (max-width: $smedium) {
    .productCardContainer {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      grid-gap: $vertical-padding;
      justify-items: center;
    }
  }
}
