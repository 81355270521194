@import '../variables.scss';

.card {
  background-color: $primary;
  border-radius: 17px;
  padding: 1rem;
  // max-width: 1200px;
  
  &-centered {
    margin-right: auto;
    margin-left: auto;
  }
}
