@import '../variables.scss';

.navbar {
  z-index: 100;
  height: $navbar-height;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  transition: box-shadow 0.5s ease-in-out;
  position: fixed;
  width: 100%;
  top: $lng-navbar-height;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-right: 1rem;
  padding-left: 1rem;
  box-sizing: border-box;

  &__linkAlignRight {
    margin-right: 2rem;
  }

  &__hideLinks__linkAlignLeft {
    margin-left: 2rem;
  }

  .button {
    font-size: $navbar-link-size;
  }

  a {
    text-decoration: none;
    font-family: 'Roboto-Medium';
    font-size: $navbar-link-size;
    color: $color-nav-link;
    letter-spacing: 0.235px;
    text-shadow: 0;
    -webkit-text-stroke-width: 0;
    // letter-spacing: 0.75px;
    transition: 0.25s ease-in-out;

    &:visited {
      color: $color-nav-link;
    }

    &:link {
      color: $color-nav-link;
    }
  }
}

.noTop {
  top: 0 !important;
}

.navWithLang {
  display: flex;
  flex-direction: column;
  position: fixed;
  z-index: 100;
  width: 100%;
  top: 0;

  a {
    color: white;
  }

  .langDiv {
    background-color: $tertiary;
    height: $lng-navbar-height;

    img {
      width: 10px;
      margin-left: 5px;
      margin-right: 5px;
      margin-bottom: 2px;
      transition: transform 0.35s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    }
  }

  .paragraph:hover {
    cursor: pointer;
  }

  .hideLang {
    display: none;
  }

  .dropdownLinkLang {
    display: flex;
    align-items: center;
    flex-direction: row;
    transition: height 0.75s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    justify-content: space-between;
    height: 100%;

    &>a,
    &>div>a {
      margin: 0.25rem 0.5rem;
      transition: font-weight 0.15s ease-in-out;
      line-height: 220%;
      border-bottom: 1px solid transparent;
      margin-top: -1px !important;
    }

    &>img {
      width: 10px;
      margin-left: -3px;
      margin-right: 5px;
      transition: transform 0.35s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    }

    .closeIconLang {
      padding-right: 5px;

      &:hover {
        transform: scale(1.1);
        cursor: pointer;
      }
    }

    a {
      font-family: "Roboto-Medium";
    }

    .productsLink {
      padding-left: 10px;

    }

    .dropdown::before {
      position: absolute;
      top: -20px;
      left: 0;
      width: 100%;
      height: 20px;
      content: '';
      display: block;
      z-index: 1;
    }



    .dropdown {
      position: absolute;
      box-shadow: 0 10px 15px -3px rgba(46, 41, 51, 0.08), 0 4px 6px -2px rgba(71, 63, 79, 0.16);
      font-size: 0.875rem;
      z-index: 9999;
      min-width: 6rem;
      list-style: none;
      background-color: $white;
      border-radius: 0.5rem;
      display: none;
      top: 0px;
      height: 0;
      padding: 0.7rem 2rem;
      transition: 0.75s cubic-bezier(0.175, 0.885, 0.32, 1.275);
      margin-top: 2rem;



      .menu-items>.paragraph {
        margin-left: 0rem !important;
        margin-right: 0rem !important;
        font-size: 15px;
        color: $primary;

        // margin-bottom: 10rem !important;
        &:hover {
          text-shadow: 0.25px 0 0 $primary;
          -webkit-text-fill-color: $primary;
          -webkit-text-stroke-width: 0.5px;
          -webkit-text-stroke-color: $primary;
        }
      }
    }


    .langDropdown:hover>.dropdown {
      height: auto;
      transition: height 0.75s cubic-bezier(0.175, 0.885, 0.32, 1.275);
      display: block;
    }

    .langDropdown:hover img {
      transition: transform 0.35s cubic-bezier(0.175, 0.885, 0.32, 1.275);
      transform: rotate(180deg);
    }
  }


}

.cartContainer {
  position: fixed;
  z-index: 101;
  top: 0;
  width: 100%;
  transition: opacity 200ms ease-in-out;
  display: flex;
  align-content: space-around;
  flex-direction: column;


  &.opacity {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5764705882);
    z-index: 101;
    display: flex;
    transition: opacity 200ms ease-in-out;
    align-items: flex-start;
    flex-direction: row-reverse;
  }


  .cartContentBottom {
    width: 100%;
    height: 100%;
    transition: height 0.3s ease-in-out;

  }

  .cartContent {
    overflow: scroll;
    transition: max-height 0.3s ease-in-out;
    background-color: $tertiary;
    width: 100%;
    max-height: 0;
    resize: vertical;

    // margin-top: $navbar-height;

    .closeIconContainer {

      margin: 20px;

      .closeIcon {
        width: 16px;
        display: block;
        margin-left: auto;
        cursor: pointer;
      }
    }

    &.expanded {
      background-color: $tertiary;
      transition: max-height 0.3s ease-in;
      max-height: none;
      max-width: 500px;
      width: 100%;
      // width: 500px;
      height: 100%;


      .closeIconContainer {
        position: absolute;
        top: 0;
        right: 0;
      }

      // height: 150%;
    }



    .cartContainerInner {
      padding: 0 45px 35px 45px;
      // display: flex;
      // flex-direction: column;
      // justify-content: space-between;
      height: 100%;
      height: auto;

      .topContent {}

      .columnNames {
        display: grid;
        grid-template-columns: 0.65fr 0.15fr 0.1fr;
        grid-template-rows: 1fr;
        grid-column-gap: 20px;
        grid-row-gap: 0px;
        margin-bottom: 1.5rem;

        .productColumn {
          grid-area: 1 / 1 / 2 / 2;
        }

        .quantityColumn {
          grid-area: 1 / 2 / 2 / 3;
          justify-self: center;
          align-self: center;
        }

        .removeColumn {
          grid-area: 1 / 3 / 2 / 4;
        }
      }

      .bottomContent {}

      .productRow {
        // display: grid;
        // grid-template-columns: 0.65fr 0.15fr 0.1fr;
        // grid-template-rows: 1fr;
        // grid-column-gap: 20px;
        // grid-row-gap: 0px;
        margin-bottom: 2rem;
        display: flex;
        flex-direction: column;
        padding-bottom: 1rem;
        border-bottom: 1px solid #444444;

        &:last-of-type {
          border-bottom: none
        }


        .productLeft {
          display: flex;
          flex-direction: row;
          grid-area: 1 / 1 / 2 / 2;

          .productImage {
            &:hover {
              cursor: pointer;
            }

            .noImgBg {
              background-color: $grey;
              max-width: 80px;
              max-height: 100px;
              height: 100%;
              z-index: 10;
            }

            .imgBackground {
              height: 50px;
              width: 60px;
              border-radius: 5px;
              /* min-height: 90px; */
              /* max-width: 390px; */
              /* max-height: 250px; */
              background-color: white;
              overflow: hidden;
              padding: 10px;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;

              img {
                width: auto;
                height: 100%;
                /* border-radius: 10px; */
                max-width: 100px;
                object-fit: cover;
                z-index: 9;
                max-width: 100px;
                position: relative;
              }
            }
          }

          .leftContent {
            display: flex;
            flex-direction: column;
            margin-left: 30px;
            justify-content: flex-start;


            .productTitle {
              &:hover {
                cursor: pointer;
              }

              display: flex;
              flex-direction: column;
              justify-content: center;
              color: $white;
              // font-family: "Roboto-medium";
            }

            .productRef {
              font-size: 13px;
              word-wrap: break-word;
              word-break: break-all;
            }

            .productPrice {
              margin-right: 1rem;
              font-family: "Roboto-bold" !important;
            }




          }


        }

        .productRight {
          display: flex;
          flex-direction: row;
          align-items: center;
          margin-top: 1rem;
          justify-content: space-between;




          .quantityColumn {
            justify-self: center;
            align-self: center;
            justify-self: center;
            grid-area: 1 / 2 / 2 / 3;

            .productQuantity {
              margin-top: 5px;

              display: flex;
              align-content: center;
              align-items: center;

              .paragraph {
                margin-right: 1rem;

              }

              input {
                color: #d1d1d1;
                padding: 5px;
                width: auto;
                background-color: #3c3c3c;
                border: 0;
                border-radius: 5px;
                outline: none;
                min-width: 30px;
                // font-size: 11px;
                text-align: center;
                margin-right: 0.5rem;
                margin-left: 0.5rem;
              }

              input[type=number]::-webkit-inner-spin-button,
              input[type=number]::-webkit-outer-spin-button {
                -webkit-appearance: none;
                margin: 0;
              }



              img {
                height: 12px;
                width: 12px;
                margin-right: 1rem;
                margin-left: 1rem;
                transition: transform 0.2s ease-in-out;
                padding: 10px;
                border-radius: 100%;
                background-color: #565656;
                &:first-of-type{
                  margin-left: 0;

                }

                &:hover {
                  transform: scale(1.05);
                  transition: transform 0.2s ease-in-out;
                  transition: background-color 0.2s ease-in-out;
                  cursor: pointer;
                  background-color: #3c3c3c;


                }
              }
            }

            grid-area: 1 / 2 / 2 / 3;
          }


        }

        .removeColumn {
          justify-self: flex-start;
          align-self: center;
          grid-area: 1 / 3 / 2 / 4;

          margin-top: 1rem;
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: flex-end;

          .productRemove {

            .paragraph {
              color: #e51e42;
              transition: 0.5s ease-in-out;
              font-size: 13px;
              text-align: right;

              &:hover {
                cursor: pointer;
                color: #ff1500;
              }
            }
          }

          grid-area: 1 / 3 / 2 / 4;
        }



      }


    }
  }


}

#servicesDropdown {
  z-index: 999;
}


a:hover {
  color: $color-nav-link;
  // font-family: 'Roboto-Bold';
  text-shadow: 0.25px 0 0 $white;
  // -webkit-text-fill-color: $white;
  -webkit-text-stroke-width: 0.5px;
  transform: scale(1.1);
  // -webkit-text-stroke-color: $white;

  transition: 0.25s ease-in-out;
}


a:visited {
  color: white;
  text-decoration: none;
}

.scrolled {
  -webkit-box-shadow: 0px 4px 3px 0px rgba(66, 68, 90, 0.1);
  -moz-box-shadow: 0px 4px 3px 0px rgba(66, 68, 90, 0.1);
  box-shadow: 0px 4px 3px 0px rgba(66, 68, 90, 0.1);
  background-color: #101011;

  // background-color: black;
}

.mobile-logo {
  display: none;
}

.logo {
  width: 10rem;
  // margin-left: 2rem;
  // margin-top: 10px;
}

.links {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  flex-direction: column;
  height: 100%;

  .topLinks {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: center;

    .leftContent,
    .rightContent {
      display: flex;
      flex-direction: row;
      align-items: center;

      a {
        margin-right: 20px;
      }
    }

    .leftContent {
      width: 100%;


      // div {
      //   width: 100%;

      //   &>.jifbFL {
      //     max-width: 500px;
      //     width: 100%;

      //     .gAvAzt {
      //       .ilZVLX {
      //         border-radius: 10px;


      //       }

      //     }

      //     .react-search-box-dropdown {
      //       margin-top: 3rem;
      //       position: absolute;
      //       max-width: 500px;
      //       width: 100%;
      //     }
      //   }
      // }
    }
  }

  .mainLinks {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;

    &>a,
    &>div>a {
      margin: 0.25rem 0.5rem;
      // transition: font-weight 0.15s ease-in-out;
      line-height: 220%;
      border-bottom: 1px solid transparent;
      margin-top: -1px !important;
    }

    .dropdownLink {
      display: flex;
      align-items: center;
      flex-direction: row;
      transition: height 0.75s cubic-bezier(0.175, 0.885, 0.32, 1.275);
      // justify-content: space-between;

      &>img {
        width: 10px;
        margin-left: -3px;
        margin-right: 5px;
        margin-bottom: 5px;
        transition: transform 0.35s cubic-bezier(0.175, 0.885, 0.32, 1.275);
      }

      &>.expandRightIcon {
        margin: 0;
        margin-left: 20px;
        width: 10px;
      }

      &>.productsLink>img {
        width: 6px;
        margin-left: -3px;
      }

      .dropdown::before {
        position: absolute;
        top: -20px;
        left: 0;
        width: 100%;
        height: 20px;
        content: '';
        display: block;
        z-index: 1;
      }

      .nestedProducts {
        .menu-items {
          font-size: 12px;
        }

      }

      .closeDropdown {
        display: none !important;
      }

      .dropdown {
        position: absolute;
        left: auto;
        box-shadow: 0 10px 15px -3px rgba(46, 41, 51, 0.08), 0 4px 6px -2px rgba(71, 63, 79, 0.16);
        font-size: 0.875rem;
        z-index: 9999;
        min-width: 11rem;
        list-style: none;
        background-color: #1a1a1a;
        border-radius: 0.5rem;
        top: 30px;
        max-height: 0;
        transition: max-height 0.25s cubic-bezier(.57, .21, .69, 1.25);
        // transition: padding 0.75s cubic-bezier(.57,.21,.69,1.25);
        // transition: display 0.75s cubic-bezier(0.175, 0.885, 0.32, 1.275);
        padding: 0 2.5rem;

        margin-top: 6rem;
        overflow: hidden;

        &>li>a {
          color: white;
          line-height: 200%;
          font-size: $xsmall-font;
        }

        &.langChange {
          min-width: auto;

          &>li.menu-items {


            &>.paragraph {
              display: flex;
              flex-direction: row;
              justify-content: flex-start;
              width: fit-content;
              color: white;
              line-height: 200%;
              font-size: 12px;
              font-family: 'Roboto-medium';
              align-items: center;

              transition: transform 0.35s cubic-bezier(0.175, 0.885, 0.32, 1.275);


              &:hover {
                transform: scale(1.1);
              }

              &>.flag {
                margin-right: 1rem;
                line-height: 0;
              }
            }
          }
        }

        &>.dropdownLink,
        .menu-items {
          // opacity: 0;
          // visibility: hidden;
          // transform: translateY(5px);
          // transition: transform 0.25s cubic-bezier(.57,.21,.69,1.25);

        }

        &>.dropdownLink>.menu-items {
          margin-bottom: 0;
        }

        &.services>.menu-items:first-child {
          margin-top: 1rem;
        }

        &>.dropdownLink:first-child {
          margin-top: 1rem;
        }

        &>.dropdownLink>.menu-items>.productsLink>a {
          color: white;
          line-height: 200%;
          font-size: $xsmall-font;
        }

        .menu-items>a {
          margin-left: 0rem !important;
          margin-right: 0rem !important;
          margin-bottom: 10rem !important;

          &:hover {
            text-shadow: 0.25px 0 0 $white;
            -webkit-text-fill-color: $white;
            -webkit-text-stroke-width: 0.5px;
            -webkit-text-stroke-color: $white;
          }
        }


      }

      .nested {
        left: 210px;
        top: -112px;
        background-color: $quaternary;
        max-width: 9rem;
        z-index: 9000;

        &::before {
          position: absolute;
          top: -10px;
          left: -80px;
          width: 100%;
          height: 100%;
          content: "";
          display: block;
          z-index: -1;
        }


      }

      // &:focus > .dropdown {
      //     display: none;

      // }

      &:hover>.dropdown {
        max-height: 260px;
        padding: 1rem 2.5rem;
        overflow: visible;

        &>.dropdownLink,
        .menu-items {
          // opacity: 1;
          // visibility: visible;
          transform: translateY(0);
        }

        &>.dropdownLink:first-child {
          margin-top: 0rem;
        }

        &.services>.menu-items:first-child {
          margin-top: 0rem;
        }
      }


      &:hover>img {
        transition: transform 0.35s cubic-bezier(0.175, 0.885, 0.32, 1.275);
        transform: rotate(180deg);
      }

      &:hover>.expandRightIcon {
        transition: transform 0.35s cubic-bezier(0.175, 0.885, 0.32, 1.275);
        transform: rotate(-90deg);
      }

    }

    .productsNested {
      margin: 0;
      margin-bottom: 0.75rem;

      .dropdown {

        &>.menu-items:first-child {
          margin-top: 1rem;
        }
      }

      &:hover>.dropdown {
        &>.menu-items:first-child {
          margin-top: 0rem;
        }
      }
    }

    .dropdown.show {
      display: block;
      max-height: 200px;
    }
  }

  .contactButton,
  .cartButton {
    // margin-right: 2rem;
    display: flex;
    flex-direction: row;
    transition: 0.35s ease-in-out;
    align-items: center;
    position: relative;

    .paragraph {
      display: flex;
      position: absolute;
      bottom: 0;
      right: 0;
      margin-right: 3px;
      background: $green;
      color: $quaternary;
      border-radius: 70%;
      padding: 1px;
      font-size: 10px !important;
      font-weight: bold;
      width: 17px;
      height: 17px;
      text-align: center;
      flex-direction: column;
      justify-content: center;
      font-family: "Roboto-bold";
    }

    &:hover {
      &>img {
        filter: invert(1);
      }
    }

    &>img {
      width: 15px;
      margin-left: 12px;
      transition: 0.35s ease-in-out;
    }
  }

  .cartButton {
    border: 0;
    padding: 0.5rem 1rem;
    margin-right: 0.5rem;

    &>img {
      width: 20px;
      margin-left: 0;
      transition: 0.35s ease-in-out;
    }
  }
}

.selectedLink {
  border-bottom: 1px solid white !important;
  margin-top: -1px !important;
  text-shadow: 0px 0px 1px $color-nav-link !important;
}

.hideLinks {
  display: none;
}


.menuIcon {
  display: none;
  // width: 25px;
  transition: transform 0.75s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.mobileCartButton {
  display: none;
  margin-right: 60px !important;
  padding: 0.5rem 1rem !important;
  border: 0 !important;

  &:hover img {
    filter: invert(1);
  }

  &>img {
    width: 25px;
    margin-left: 0;
    transition: 0.35s ease-in-out;

  }
}

.menuIcon.change {
  transform: rotate(180deg);
}

.extendedNavbar {
  position: absolute;
  top: 4rem;
  left: 0;
  flex-direction: column;
  width: 100%;
  height: 0;
  background-color: transparent;
  display: none;
  transition: height 0.75s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  background-color: #101011;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;

  .closeDropdown {
    display: none !important;
  }

  .dropdown {
    display: none;
    // z-index: 10000;
    position: absolute;
    min-width: 10rem;
    box-shadow: 0 10px 15px -3px rgba(46, 41, 51, 0.08), 0 4px 6px -2px rgba(71, 63, 79, 0.16);
    font-size: 0.875rem;
    min-width: 14rem;
    list-style: none;
    background-color: $tertiary;
    border-radius: 17px;
    height: 0;
    top: 0;
    left: 0;
    padding: 1.5rem 2rem;

    opacity: 1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 0%);

    .menu-items>a {
      margin-left: 0rem !important;
      margin-right: 0rem !important;
      margin-bottom: 10rem !important;
    }

  }

  .dropdown:hover {
    display: block;
  }

  &>.showLinks>.dropdownLink>.productsLink>a {
    color: white;
    text-decoration: none;
  }

  .contactButton {
    &:hover {
      &>img {
        filter: invert(1);
      }
    }

    &>img {
      width: 15px;
      margin-left: 12px;
      transition: 0.35s ease-in-out;
    }
  }
}

.closeDropdown {
  display: none !important;
}

.extendedNavbar.change {
  height: auto;
  padding-top: 1rem;
}

.showLinks {
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: 0.75s cubic-bezier(0.175, 0.885, 0.32, 1.275);

  .dropdownLink {
    z-index: 1000;
    position: relative;

    &>img {
      width: 15px;
      margin-left: 12px;
      transition: 0.35s ease-in-out;
    }
  }

  .contactButton,
  .cartButton {
    &>img {
      width: 15px;
      margin-left: 12px;
      transition: 0.35s ease-in-out;
    }
  }

}

.menu-items:not(:last-child) {
  margin-bottom: 0.75rem;
}

@media screen and (max-width: 768px) {
  .navbar_logo {
    animation: none !important;
    transition: none !important;
  }

  .langDiv {
    .dropdownLink {
      margin: 0;
    }
  }

  a,
  .button,
  .dropdownLink {
    margin: 1rem;
  }

  .navbar {
    height: 5rem;

    .topLinks {
      display: none;
    }

    a:not(.navbar_logo),
    .button,
    .dropdownLink {
      margin: 1rem;

      // -webkit-animation: bottomSlide 0.75s cubic-bezier(0.175, 0.885, 0.32, 1.275) both;
      // animation: bottomSlide 0.75s cubic-bezier(0.175, 0.885, 0.32, 1.275) both;
      // transition: 0.75s cubic-bezier(0.175, 0.885, 0.32, 1.275);
      .expandRightIcon {
        transform: rotate(270deg);
      }

      &:hover>.expandRightIcon {
        transition: transform 0.35s cubic-bezier(0.175, 0.885, 0.32, 1.275);
        transform: rotate(360deg);
      }

      .nested {
        top: 29px;
        background-color: $quaternary;

        &>.dropdownLink:first-child {
          margin-top: 0rem;
        }

        // min-width: 9rem;
        // z-index: 9;

        &::before {
          position: absolute;
          top: -20px;
          width: 100%;
          height: 100%;
          content: "";
          display: block;
          z-index: -1;
        }
      }
    }

    .productsNested {
      margin: 0;
      margin-bottom: 0.75rem;
      display: flex;
      flex-direction: row;
      align-items: center;
      // z-index: 10 !important;

      .menu-items {
        margin-bottom: 0.35rem;

        a {
          margin: 0;
        }
      }

      .expandRightIcon {
        width: 10px !important;
      }

      &>.menu-items {
        margin-bottom: 0;
      }



    }

    .mobileCartButton {
      margin-right: 2rem;
      display: flex;
      flex-direction: row;
      transition: 0.35s ease-in-out;
      align-items: center;
      position: relative;

      .paragraph {
        display: flex;
        position: absolute;
        bottom: 0;
        right: 0;
        margin-right: 3px;
        background: $green;
        color: $quaternary;
        border-radius: 70%;
        padding: 1px;
        font-size: 12px !important;
        font-weight: bold;
        width: 15px;
        height: 15px;
        text-align: center;
        flex-direction: column;
        justify-content: center;
      }

      &:hover {
        &>img {
          filter: invert(1);
        }
      }

      &>img {
        width: 22px;
        transition: 0.35s ease-in-out;
      }
    }



    .dropdown::before {
      position: absolute;
      top: -20px;
      left: 0;
      width: 100%;
      height: 20px;
      content: '';
      display: block;
      // z-index: 1;
    }

    background-color: #101011;

    .mobile-logo {
      display: contents;
    }

    .navbarTopContent {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
      margin-bottom: 1rem;
      height: 100%;
      justify-content: space-between;

      &>.menuIcon {
        position: absolute;
        right: 0;
        margin-right: 2rem;
        height: $navbar-menu-icon-height;
      }
    }

    &__logo {
      margin-left: 1.5rem;
      margin-bottom: 1rem;

      img {
        height: $navbar-menu-icon-height + 25px;
        width: auto;
        align-self: center;
      }
    }
  }

  .dropdown {
    left: auto;
    box-shadow: 0 10px 15px -3px rgba(46, 41, 51, 0.08), 0 4px 6px -2px rgba(71, 63, 79, 0.16);
    font-size: 0.875rem;
    // z-index: 10000;
    list-style: none;
    background-color: #0f0f0f;
    border-radius: 0.5rem;
    display: none;
    top: 35px;
    height: 0;
    transition: height 0.75s cubic-bezier(0.175, 0.885, 0.32, 1.275);

    &>li>a {
      color: white;
      line-height: 200%;
    }

    .productsNested {
      margin-top: 0.25rem;
      margin-bottom: 1.25rem;
      width: max-content;

      &:last-of-type {
        margin-bottom: 0.9rem;
      }
    }

    &.langChange {
      min-width: auto;

      &>li.menu-items {

        &>.paragraph {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          width: fit-content;
          color: white;
          line-height: 200%;
          font-size: 12px;
          font-family: 'Roboto-medium';
          align-items: center;

          transition: transform 0.35s cubic-bezier(0.175, 0.885, 0.32, 1.275);

          &:hover {
            transform: scale(1.1);
          }

          &>.flag {
            margin-right: 1rem;
            line-height: 0;
          }
        }
      }
    }
  }



  .dropdownLink>ul {
    padding: 0;
  }

  .dropdownLink>.dropdown {
    height: 0;
    opacity: 0;
    transition: height transform 0.75s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }

  .dropdownLink {
    &.langChange {
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 18px;
      z-index: 100;
    }
  }

  .dropdownLink:hover>.dropdown {
    box-sizing: border-box;
    border-radius: 0;
    display: block;
    height: auto;
    opacity: 1;
    width: 100vw;
    box-shadow: none;

    &.langChange {
      width: auto;
      border-radius: 10px;
    }

    &.nested {
      width: 100vw;
      border-radius: 0;
      box-shadow: none;
      position: fixed;
      margin-top: 2rem;
    }

    &>.menu-items {
      width: fit-content;
    }
  }


  .dropdownLink>.productsLink {
    text-align: center;
  }

  .dropdownLink>.productsLink>img {
    transition: transform 0.35s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    width: 10px;
    margin-left: -3px;
    margin-right: 5px;
  }

  .dropdownLink:hover>.productsLink>img {
    transform: rotate(180deg);
  }

  .logo {
    margin-left: 0;
  }

  .selectedLink {
    margin-top: 1rem !important;
  }

  .extendedNavbar {
    display: block;

    .showLinks {
      .searchBar {
        padding: 0px 15px;
      }
    }
  }

  .links {
    .contactButton {
      margin-right: 0;
    }
  }

  .links>.mainLinks>a,
  .links>.mainLinks>.dropdownLink,
  .links>.button {
    display: none;
  }

  .menuIcon {
    display: block;
  }

  .mobileCartButton {
    display: block;
  }

  .cartContainer {
    .cartContent {
      &.expanded {
        width: 100%;
        height: auto;
      }

      .cartContainerInner {
        .topContent {
          .columnNames {
            display: none;
          }
        }

        .bottomContent {
          .button {
            margin-left: 0;
          }
        }

        .productRow {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          max-width: 900px;
          margin-bottom: 2rem;


          .productLeft {
            width: 100%;
            display: flex;
            flex-direction: row;

            .productImage {
              &:hover {
                cursor: pointer;
              }

              .noImgBg {
                background-color: $grey;
                max-width: 80px;
                max-height: 100px;
                height: 100%;
                z-index: 10;
              }

              .imgBackground {
                height: 70px;
                width: 50px;
                border-radius: 5px;
                /* min-height: 90px; */
                /* max-width: 390px; */
                /* max-height: 250px; */
                background-color: white;
                overflow: hidden;
                padding: 10px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                img {
                  width: 100%;
                  /* height: 100%; */
                  /* border-radius: 10px; */
                  max-width: 100px;
                  object-fit: cover;
                  z-index: 9;
                  max-width: 100px;
                  position: relative;
                }
              }
            }

            .leftContent {
              width: 100%;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              margin-left: 15px;


              .productTitle {
                &:hover {
                  cursor: pointer;
                }

                display: flex;
                flex-direction: column;
                justify-content: center;
                color: $white;
                font-family: "Roboto-medium";
              }

              .productPrice {
                margin-right: 1rem;
                font-family: "Roboto-bold" !important;
              }


            }

          }

          .bottomContent {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            margin-top: 1rem;

            .productRemove {
              margin-top: 1rem;
              width: 100%;
              display: flex;
              flex-direction: row;
              justify-content: center;

              .paragraph {
                color: #e51e42;
                transition: 0.5s ease-in-out;
                font-size: 13px;

                &:hover {
                  cursor: pointer;
                  color: #ff1500;
                }
              }
            }

            .productQuantity {
              margin-top: 5px;

              display: flex;
              align-content: center;
              align-items: center;

              .paragraph {
                margin-right: 0.5rem;
              }

              input {
                color: #d1d1d1;
                padding: 5px;
                width: auto;
                background-color: #3c3c3c;
                border: 0;
                border-radius: 3px;
                outline: none;
                min-width: 30px;
                // font-size: 11px;
                margin-right: 1rem;
                margin-left: 1rem;
              }

              input[type=number]::-webkit-inner-spin-button,
              input[type=number]::-webkit-outer-spin-button {
                -webkit-appearance: none;
                margin: 0;
              }

              img {
                // height: 20px;
                // width: 15px;
                // margin-right: 0.5rem;
                // margin-left: 0.5rem;
                // transition: transform 0.2s ease-in-out;

                height: 12px;
                width: 12px;
                margin-right: 1rem;
                margin-left: 1rem;
                transition: transform 0.2s ease-in-out;
                padding: 10px;
                border-radius: 100%;
                background-color: #565656;

                &:hover {
                  transform: scale(1.35);
                  transition: transform 0.2s ease-in-out;

                }
              }
            }
          }




        }
      }


    }
  }

  .cartContainer {



    &.opacity {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.5764705882);
      z-index: 101;
      display: flex;
      transition: opacity 200ms ease-in-out;
      align-items: flex-start;
      flex-direction: column;
    }

    &>.cartContentBottom {
      width: 100%;
      height: 20%;
      transition: height 0.3s ease-in-out;
    }

    &>.cartContent.expanded {
      max-width: none;
      width: 100%;
      // width: 500px;
      height: auto;
    }

  }

}

@media screen and (min-width:$larger) {
  .cartContainer .cartContent.expanded {
    // max-height: 700px;
  }
}

@-webkit-keyframes bottomSlide {
  0% {
    -webkit-transform: translateY(-100px);
    transform: translateY(-100px);
  }

  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
}

@keyframes bottomSlide {
  0% {
    -webkit-transform: translateY(-200px);
    transform: translateY(-200px);
  }

  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
}

@media screen and (max-width: $large) {
  .navbar {
    .button {
      font-size: $navbar-link-size - 2;
    }

    &>a {
      font-size: $navbar-link-size - 2;
    }
  }
}