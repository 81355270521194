@import '../variables.scss';

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  text-align: center;
  justify-content: center;
  height: 90vh;
  z-index: 15;
  background-image: url('../../assets/images/nasa.webp');
  background-size: cover;
  width: 100vw !important;
  margin-bottom: $horizontal-padding;

  .button {
    // transition: none;
    animation: pop 1s ease-in-out both
  }

  .title {
    animation: pop 0.9s ease-in-out both
  }

  .subtitle {
    animation: pop 0.6s ease-in-out both
  }

  .buttonsContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .button {
      transition: all 0.35s ease-in-out;
    }

    .button:first-child {
      margin-right: 1rem;
    }

    #learnMore {
      margin: 0;
    }
  }

  .headerButton>img {
    transition: 0.35s ease-in-out;
  }

  .headerButton:hover>img {
    filter: invert(1);
    transition: all 0.35s ease-in-out;

  }

  .subtitle {
    // padding-right: 3rem;
    line-height: 200%;
    margin-bottom: 2rem;
  }

  &__image {
    max-width: 90%;
    max-height: 600px;
    margin-top: 15%;
    object-fit: cover;
    justify-self: center;
    align-self: center;
    animation: bounce 2s infinite alternate-reverse ease-in-out;
    margin-right: auto;
    margin-left: auto;
  }

  &__text {
    justify-self: left;
    align-self: center;
    max-width: 600px;
    padding-right: 2rem;
    padding-left: 2rem;
  }
}

@media screen and (max-width: $larger) {
  .header {
    .title {
      font-size: $bigger-font;
    }
  }
}

@media screen and (max-width: $large) {
  .header {
    .title {
      // font-size: $big-font;
    }

    &__image {
      // max-width: 70%;
    }

    .buttonsContainer {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      flex-direction: column;
      align-items: center;

      .button:first-child {
        margin-right: 0;
        margin-bottom: 1rem;
      }
    }
  }
}

@media screen and (max-width: $lmedium) {
  .header {
    .title {
      font-size: $big-font !important;
    }

    .subtitle {
      // font-size: $smaller-font;
      padding-right: 0;
    }

    &__image {
      // max-width: 80%;
    }

    &__text {
      margin-top: 2rem;
      justify-self: center;
      align-self: center;
      text-align: center;
    }
  }
}

@media screen and (max-width: 810px) {
  .header {
    .buttonsContainer {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      .button:first-child {
        margin-right: inherit;
      }
    }
  }
}

@media screen and (max-width: $medium) {
  .header {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: 1fr;

    .buttonsContainer {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      .button:first-child {
        margin-right: 1rem;
      }
    }

    .title {
      font-size: $medium-font;
    }

    .subtitle {
      padding-right: 0;
    }

    &__image {
      // max-width: 45%;
      margin-right: 0;
      margin-left: 0;
    }

    &__text {
      margin-top: 2rem;
      justify-self: center;
      align-self: center;
      text-align: center;
    }
  }
}

@media screen and (max-width: $small) {
  .header {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: 1fr;

    &__image {
      // max-width: 50%;
    }

    &__text {
      margin-top: 2rem;
      justify-self: center;
      align-self: center;
      text-align: center;
    }
  }
}

@keyframes bounce {
  0% {
    transform: translateY(-5px);
    filter: drop-shadow(0px 0px 10px #e0e0e09b);
  }

  100% {
    transform: translateY(5px);
    filter: drop-shadow(5px 5px 10px #e0e0e022);
  }
}

@keyframes bounceUp {
  0% {
    transform: translateY(-35px);
    filter: drop-shadow(0px 0px 10px #e0e0e09b);
  }

  100% {
    transform: translateY(-45px);
    filter: drop-shadow(5px 5px 10px #e0e0e022);
  }
}

@keyframes pop {
  0% {
    transform: scale(90%);
  }

  50% {
    transform: scale(105%);
  }

  100% {
    transform: scale(100%);
  }
}