@import '../variables.scss';

.modal {
    // height: auto;

    .productModalContainer {
        display: grid;
        grid-template-columns: 0.5fr 1fr;
        grid-template-rows: 1fr;
        grid-column-gap: 40px;
        grid-row-gap: 0px;
        height: 100%;
    }

    .imageDiv {
        grid-area: 1 / 1 / 2 / 2;
        // background: rgba(255, 255, 255, 0.53);
        border-radius: 17px;
        background-size: cover;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        img {
            width: 95%;
            // height: 100%;
            object-fit: cover;
            border-radius: 10px;
        }
    }

    .contentDiv {
        grid-area: 1 / 2 / 2 / 3;

        .productTitle {
            font-family: $font-medium;
            color: $white;
            font-size: $slarge-font;
            margin-bottom: 0;
            margin-top: 0;
        }

        .productBrand {
            font-family: $font-medium;
            font-size: $small-font;
            color: $mediumGrey;
            margin-bottom: 20px;

        }

        .buttonContainer {
            margin-top: 1rem;
            display: flex;
            flex-direction: row;
            align-items: center;

            input {
                margin-left: 1rem;
                padding: 5px;
                width: 40px;
                background-color: white;
                border: 0;
                border-radius: 3px;
                outline: none;
                font-family: $font;
            }
        }

        .productSpecifications {
            font-family: 'Roboto-black';
            margin-top: 15px;
            width: fit-content;


            a {
                color: $secondary;
                text-decoration: underline;
                margin-left: 0;
                display: flex;
                flex-direction: row;
                align-items: center;

                .downloadIcon {
                    margin-left: 5px;
                    color: $secondary;
                    height: 22px;
                }



                &:hover {
                    transform: none;
                }
            }
        }

        .productPrice {
            margin-top: 3rem;
            color: $green;
            font-family: 'Roboto-bold';
            font-size: $large-font;
            margin-bottom: 0;
        }

        .outOfStockPrice {
            color: $grey;
        }

        .productStock {
            margin-top: 0;
            margin-bottom: 3rem;
        }

        .button {
            display: flex;
            align-items: center;

            .cartIcon {
                margin-left: 5px;
                height: 22px;
            }

            &:hover>img {
                filter: invert(1);
            }

        }
    }
}

@media screen and (max-width: 768px) {
    .modal {
        max-height: 80%;
        .productModalContainer {
            display: grid;
            grid-template-columns: 1fr;
            grid-template-rows: 0.5fr 1fr;
            grid-column-gap: 0px;
            grid-row-gap: 10px;
        }

        .imageDiv {
            grid-area: 1 / 1 / 2 / 2;

            padding: 10px;

            img {
                width: 100%;
                max-height: 300px;
            }
        }

        .contentDiv {
            grid-area: 2 / 1 / 3 / 2;
            padding: 10px;

            .buttonContainer {

                // justify-content: center;
                .button {
                    margin-left: 0;
                }
            }

            .productStock {
                margin-bottom: 1.5rem;
            }

            // .productPrice,
            // .productStock {
            //     text-align: center;
            // }

        }
    }
}