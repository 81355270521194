@import '../variables.scss';

.searchBar {
    position: relative;
    max-width: 500px;
    width: 100%;
    box-sizing: border-box;
    // padding: 0 20px;




    .input-wrapper {
        background-color: transparent;
        // width: 100%;
        border-radius: 10px;
        height: 2.5rem;
        padding: 0 15px;
        display: flex;
        align-items: center;
        // max-width: 500px;
        border: 2px solid $quaternary;

        img {
            width: 14px;
            height: 14px;
        }


        input {
            background-color: transparent;
            border: none;
            height: 100%;
            font-size: 1rem;
            width: 100%;
            margin-left: 5px;
            color: $secondary;


            &:focus {
                outline: none;
            }
        }

    }

    .results-list {
        box-sizing: border-box;
        border-radius: 10px;
        padding: 15px 20px;
        display: none;
        flex-direction: column;
        max-height: 300px;
        overflow-y: scroll;
        margin-top: 4rem;
        position: absolute;
        max-width: 500px;
        width: 100%;
        top: 0;
        left: 0;
        background-color: $tertiary;
        color: #CDCDCE;
        z-index: 10000;



        &.show {
            display: flex
        }



        &.change {
            display: none;
        }





        &>.resultsTitle {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            &>.paragraph {
                font-size: $smaller-font;

                a {
                    margin-right: 0;
                }
            }

        }

        &>.paragraph {
            font-size: $smaller-font;
        }



        &>.results-categories-content {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            margin-bottom: 10px;
            margin-top: 10px;
            overflow-x: scroll;
            padding: 20px;
            padding-left: 10px;

            // overflow-y: scroll;
        }

        &>.search-result {
            padding: 10px;
            // border-bottom: 1px solid grey;
            transition: background-color 0.25s ease-in-out;
            border-radius: 10px;




            &:hover {
                background-color: $quaternary;
                border-radius: 10px;
                cursor: pointer;
            }

            &>.search-result-item {
                display: flex;
                flex-direction: row;


                &>.result-image {
                    display: flex;
                    align-items: center;

                    img {
                        width: 45px;
                        height: 100%;
                        border-radius: $radius - 5;
                    }
                }

                &>.result-content {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    align-items: flex-start;
                    margin-left: 15px;

                    .paragraph {
                        text-align: left;
                        font-size: $smaller-font;

                        a{
                            font-size: $smaller-font;
                            margin: 0;
                            font-family: inherit;
                            &:hover{
                                text-decoration: underline;
                            }
                        }

                    }

                    .ref{
                        font-size: 10px;
                        margin-bottom: 0.25rem;
                    }
                }
            }
        }


    }

}

@media screen and (max-width: 768px) {
    .searchBar {
        .results-list {
            margin-top: 3rem;

            &.change {
                // display: none;
            }

            &.showMobile {
                display: flex
            }
        }
    }
}