@import '../../components/variables.scss';

.App {
  padding-top: 0;
}

.container {
  padding-left: 4rem;
  padding-right: 4rem;
  max-width: 1900px;
  margin-left: auto;
  margin-right: auto;
}

.items {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  // gap: 3rem;
  margin: 3rem;
  // align-items: end;
  justify-items: stretch;
  grid-column-gap: 2rem;

  .subtitle {
    line-height: 120%;
  }

  .item {
    height: 100%;
    padding-right: 2rem;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 0.7fr 1fr;
    gap: 0px 0px;
    border-right: 1px solid white;
    justify-items: center;
    grid-template-areas:
      'title'
      'description';

    .icon {
      grid-area: icon;

      .iconStyle {
        width: 5rem;
        object-fit: cover;
      }
    }

    h2,
    h3 {
      margin: 0;
    }

    .title {
      grid-area: title;
      margin-top: 0.5rem;
      max-width: 300px;
      line-height: 150%;
    }

    .description {
      grid-area: description;
      margin-top: 0.5rem;
      // font-family: 'Raleway-Medium';
      text-align: center;
      max-width: 280px;
    }
  }

  .item:last-child {
    border-right: 0;
    padding-right: 0;
  }
}

.title>span {
  font-family: $font-bolder;
  color: $green;
}

.solarPanelPlants {
  margin-bottom: 6rem;
  margin-top: 6rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr) 0.5fr 1.5fr;
  grid-template-rows: 1fr 0.5fr 0.3fr 1.5fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;

  .card>.paragraph {
    margin-top: 0.75rem;
  }
}

.titlePlants {
  grid-area: 1 / 1 / 3 / 4;

  .title {
    max-width: 600px;
  }

  .subtitle {
    max-width: 550px;
  }
}

.buttonPlants {
  grid-area: 3 / 1 / 4 / 4;
  margin-bottom: 2rem;
}

.typesContainer {
  grid-area: 4 / 1 / 5 / 6;
  align-items: center;
  max-width: 100% !important;
}

.typesOfPlants {
  grid-area: 4 / 1 / 5 / 3;
  padding-right: 400px;

  .subtitle {
    font-size: $small-font;
  }
}

.imagePlants {
  grid-area: 1 / 5 / 5 / 6;
  display: flex;
  padding-bottom: 2rem;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;

  img {
    width: 100%;
    max-width: 400px;
  }
}

.solarEquipment {
  margin-top: 4rem;
  margin-bottom: 6rem;

  .typesContainer {
    display: flex;
    justify-content: center;
    margin-top: $horizontal-padding;
    flex-wrap: wrap;

    .button {
      font-size: $mediuml-font;
      // margin: 1rem;
      height: 130px;

      &>h3 {
        font-size: $mediuml-font;
        font-family: inherit;
      }
    }
  }

  .equipmentLogosContainer {
    display: flex;
    flex-direction: row;
    margin: 5rem 0 5rem 0;
    justify-content: space-around;
    flex-wrap: wrap;
    align-content: center;

    img {
      margin: 0.5rem;
      object-fit: cover;
      height: 60px;
    }
  }

  .equipmentButton {
    display: flex;
    justify-content: center;
    margin-top: $vertical-padding;
    margin-bottom: $vertical-padding;
  }
}

.twoItemsContainer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 50px;
  padding: 1rem;
  margin: 2rem;
  margin-top: 6rem;
  margin-bottom: 6rem;

  .heatContainer,
  .chargerContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: space-between;

    .title,
    .paragraph {
      margin-bottom: 2rem;
    }
  }
}

.softwareContainer,
.heatPumpsContainer {
  display: grid;
  grid-template-columns: 1.2fr 0.8fr 1fr;
  grid-template-rows: 1fr 1fr 0.5fr;
  padding: 2rem;
  padding-bottom: 0;

  .subtitle {
    line-height: 185%;
  }

  .card>.paragraph {
    margin-top: 0.75rem;
  }

  p>span {
    font-family: $font-bolder;
    color: $green;
  }

  .titleSoftware,
  .titleHeatPumps {
    grid-area: 1 / 1 / 3 / 4;

    .title {
      max-width: 700px;
    }

    .subtitle {
      margin-top: 2rem;
      max-width: 600px;
    }

    .heatPumpsLogosContainer {
      max-width: 800px;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-content: center;
      justify-content: flex-start;
      margin-bottom: 2rem;

      img {
        margin: 1rem;
        object-fit: cover;
        height: 50px;
      }
    }
  }

  .buttonSoftware,
  .buttonHeatPumps {
    grid-area: 3 / 1 / 4 / 4;
  }

  .imageSoftware,
  .imageHeatPumps {
    grid-area: 1 / 3 / 4/ 4;
    display: flex;
    padding-bottom: 2rem;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;

    img {
      width: 100%;
      max-width: 370px;
      border-radius: 17px;
    }
  }
}

.referencesContainer {
  display: flex;
  flex-direction: column;
  // align-items: center;
  padding: 2rem;

  .referenceItems {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    justify-content: space-around;
    margin-top: 3rem;
    margin-bottom: 1rem;

    .referenceItem {
      display: flex;
      flex-direction: column;
      max-width: 380px;
      min-width: 150px;

      img {
        min-height: 300px;
        height: 100%;
        width: 100%;
        object-fit: cover;
        border-radius: 5px;
      }

    }
  }
}

.typesContainer {
  grid-area: 4 / 1 / 5 / 6;
  align-items: center;
  max-width: 100% !important;
}

.typesOfPlants {
  grid-area: 4 / 1 / 5 / 3;
  padding-right: 400px;
}

@media screen and (min-width: $larger) {

  .softwareContainer,
  .heatPumpsContainer {
    grid-template-rows: 1fr 1fr 0.5fr;

    .titleSoftware,
    .titleHeatPumps {
      .title {
        max-width: 750px;
      }

      .subtitle {
        max-width: 650px;
      }
    }
  }
}

@media screen and (min-width: 768px) {
  .solarEquipment {
    .typesContainer {
      .button {
        margin: 1rem;
      }
    }

    .equipmentButton {
      display: flex;
      justify-content: center;
      margin-top: $vertical-padding;
      margin-bottom: $vertical-padding;
    }
  }
}

@media screen and (max-width: $mlarge) {
  .typesOfPlants {
    padding-right: 300px;
  }

  .solarPanelPlants {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: 1.25fr 1.5fr 1.5fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
  }

  .typesContainer {
    margin-top: 2rem;
  }

  .imagePlants {
    grid-area: 1 / 5 / 5 / 6;
    display: flex;
    padding-bottom: 2rem;
    flex-direction: column;
    justify-content: flex-end;

    img {
      max-width: 300px;
    }
  }

  .titlePlants {
    grid-area: 1 / 1 / 3 / 6 !important;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;

    p {
      text-align: center;
    }
  }

  .buttonPlants {
    grid-area: 3 / 1 / 4 / 6 !important;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }

  .softwareContainer,
  .heatPumpsContainer {
    display: grid;
    grid-template-columns: 1.2fr 0.8fr 1fr;
    grid-template-rows: 1fr 1.5fr 0.5fr;
    padding: 2rem;
    padding-bottom: 0;

    .card>.paragraph {
      margin-top: 0.75rem;
    }

    p>span {
      font-family: $font-bolder;
      color: $green;
    }

    .titleSoftware,
    .titleHeatPumps {
      grid-area: 1 / 1 / 3 / 4;

      .title {
        max-width: 500px;
        // font-size: $big-font !important;
      }

      .subtitle {
        margin-top: 2rem;
        max-width: 450px;
        // font-size: $small-font !important;
      }

      .heatPumpsLogosContainer {
        max-width: 500px;
      }
    }
  }
}

@media screen and (max-width: $large) {
  .typesOfPlants {
    padding-right: 250px !important;
  }

  .imagePlants {
    grid-area: 1 / 5 / 5 / 6;
    display: flex;
    padding-bottom: 2rem;
    flex-direction: column;
    justify-content: flex-end;

    img {
      max-width: 250px;
    }
  }

  .items {
    grid-template-columns: 1fr 1fr;
    margin: 1rem;

    .item {
      height: auto;
      //   border-bottom: solid white 1px;
      //   padding-bottom: 25px;
      border-right: 0;
      padding-right: 0;
    }

    .item:nth-child(1),
    .item:nth-child(2) {
      border-bottom: solid white 1px;
      padding-bottom: 25px;
    }

    .item:last-child {
      grid-column: span 2;
    }
  }

  .solarEquipment {
    .typesContainer {
      .button {
        font-size: $medium-font;
        height: 100px;
        font-family: $font-bold;

        &>h3 {
          font-size: $medium-font;
          font-family: $font-bold;
        }
      }
    }

    .equipmentLogosContainer {
      img {
        height: 55px;
      }
    }
  }

  .softwareContainer,
  .heatPumpsContainer {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 1fr 1fr 0.25fr;
    padding: 2rem;
    padding-bottom: 0;

    .card>.paragraph {
      margin-top: 0.75rem;
    }

    p>span {
      font-family: $font-bolder;
      color: $green;
    }

    .titleSoftware,
    .titleHeatPumps {
      grid-area: 1 / 1 / 2 / 4;
      display: flex;
      align-items: center;
      flex-direction: column;

      .title {
        max-width: 600px;
        text-align: center;
        // font-size: $large-font !important;
      }

      .subtitle {
        margin-top: 2rem;
        max-width: 550px;
        text-align: center;
      }

      .heatPumpsLogosContainer {
        max-width: 800px;
        justify-content: center;
      }
    }

    .buttonSoftware,
    .buttonHeatPumps {
      grid-area: 4 / 1 / 5 / 4;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .imageSoftware,
    .imageHeatPumps {
      grid-area: 2 / 1 / 4 / 4;
      display: flex;
      padding-bottom: 0;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      img {
        width: 100%;
        max-width: 250px;
      }
    }
  }

  .heatPumpsContainer {
    grid-template-rows: 1fr 0.75fr 0.25fr;

    .heatPumpsLogosContainer {
      img {
        height: 55px;
      }
    }
  }
}

@media screen and (max-width: $lmedium) {
  .paragraph {
    font-size: $smaller-font !important;
  }
}

@media screen and (max-width: $medium) {
  .items {
    grid-template-columns: 1fr 1fr;
    margin: 1rem;

    .subtitle {
      font-size: $smaller-font !important;
    }

    .paragraph {
      // font-size: $xsmall-font !important;
    }

    .item {
      height: auto;
      border-right: 0;
      padding-right: 0;
      margin-right: 1rem;
      margin-left: 1rem;
    }

    .item:nth-child(1),
    .item:nth-child(2) {
      border-bottom: solid white 1px;
      padding-bottom: 25px;
    }
  }

  .solarPanelPlants {
    display: grid;
    grid-template-columns: 1fr !important;
    grid-template-rows: 0.5fr 0.75fr 1fr 0.25fr;
    gap: 10px 0px;
    grid-template-areas:
      'titlePlants'
      'imagePlants'
      'typesOfPlants'
      'buttonPlants';
  }

  .titlePlants {
    grid-area: titlePlants !important;
  }

  .buttonPlants {
    grid-area: buttonPlants !important;
  }

  .typesContainer {
    grid-area: typesOfPlants !important;
    display: flex;
    flex-shrink: 2;
  }

  .typesOfPlants {
    // grid-area: 4 / 1 / 5 / 3;
    grid-area: typesOfPlants !important;

    padding-right: 0 !important;
  }

  .imagePlants {
    grid-area: imagePlants !important;
    align-items: center;

    img {
      width: 100%;
    }
  }

  .twoItemsContainer {
    grid-template-columns: 1fr;
  }

  .solarEquipment {
    // margin-top: 0;
  }

  .solarPanelPlants {
    margin-bottom: 0;
  }

  .softwareContainer,
  .heatPumpsContainer {
    padding: 1rem;
  }
}

@media screen and (max-width: $smedium) {
  .items {
    grid-template-columns: 1fr;
    margin: 0;

    .subtitle {
      font-size: initial !important;
    }

    .paragraph {
      font-size: initial !important;
    }

    .item {
      height: auto;
      border-right: 0;
      padding-right: 0;
      border-bottom: solid white 1px;
    }

    .item:last-child {
      grid-column: span 1;
      border-bottom: 0;
    }
  }

  .card {
    padding-top: 1rem;
    padding-bottom: 1.5rem;
  }

  .solarEquipment {
    margin-top: 1rem;
    margin-bottom: 3rem;

    .typesContainer {
      .button {
        font-size: $smaller-font;

        &>h3 {
          font-size: $smaller-font;
        }
      }
    }

    .equipmentLogosContainer {
      margin-top: 1rem;
      margin-bottom: 1rem;

      img {
        height: 45px;
      }
    }
  }

  .solarPanelPlants {
    .typesContainer {
      margin-top: 0rem;
    }
  }

  .softwareContainer,
  .heatPumpsContainer {
    display: grid;
    grid-template-rows: 1fr 0.5fr 0.5fr;
    padding: 1rem;
    padding-bottom: 0;

    .titleSoftware,
    .titleHeatPumps {
      .title {
        max-width: 500px;
        // font-size: $mediuml-font !important;
      }

      .subtitle {
        line-height: 150% !important;
        margin-bottom: 2rem;
      }
    }

    .heatPumpsLogosContainer {
      img {
        height: 45px;
      }
    }
  }

  .heatPumpsContainer {
    display: grid;
    grid-template-rows: 1fr 0.25fr 0.25fr 0.15fr;
  }

  .solarEquipment {
    .typesContainer {
      margin-top: 2rem;

      a {
        margin: 1rem;
      }

      .button-image {
        max-width: 110px;
        min-width: 110px;
      }
    }

    .equipmentButton {}
  }
}

@media screen and (max-width: $lsmall) {
  .solarEquipment {
    .typesContainer {
      .button-image {
        max-width: 90px;
        min-width: 90px;
        height: 90px;
        margin: 0.5rem;

        h3 {
          font-size: $xsmall-font;
        }
      }
    }

    .equipmentButton {}

    .equipmentLogosContainer {
      img {
        height: 35px;
      }
    }
  }

  .heatPumpsContainer {
    .titleHeatPumps {
      .heatPumpsLogosContainer {
        img {
          height: 35px;
        }
      }
    }
  }
}

@media screen and (max-width: $small) {
  .solarEquipment .typesContainer .button-image {
    max-width: 75px;
    min-width: 62px;
    margin: 0.5rem;
    font-size: $xsmall-font;
  }

  .header {
    .title {
      font-size: $slarge-font !important;
    }

    .subtitle {
      font-size: $smaller-font !important;
    }
  }

  .solarEquipment {
    // grid-template-rows: 1fr 0.25fr 0.25fr;
    grid-template-rows: 1fr 0.25fr 0.5fr 0.25fr;

    .equipmentLogosContainer {
      margin: 0;
    }

    .typesContainer {
      margin: 0;
    }
  }

  .softwareContainer {
    grid-template-rows: 1fr 0.25fr 0.25fr;
  }

  .heatPumpsContainer {
    grid-template-rows: 1.5fr 0.75fr 0.15fr;
  }
}