@import '../../components/variables.scss';

.heatPumps {
  padding-top: $navbar-height;

  .title {
    margin-top: $horizontal-padding;
    font-size: $mediuml-font;
  }
  .imageContainer {
    display: flex;
    img {
      width: 100%;
      max-width: 600px;
      border-radius: 17px;
      margin: 5rem auto 5rem auto;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .paragraph {
    line-height: 200%;
    // text-align: center;
    margin-top: $vertical-padding;
  }

  .pumpWorkTitle {
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: $vertical-padding;
    text-align: left;
  }
  .landingSection {
    display: flex;
    flex-direction: column;

    .iconContainer {
      display: flex;
      // width: 700px;
      // max-width: 900px;
      justify-content: space-around;
      flex-direction: row;
      flex-wrap: wrap;
      // margin-left: auto;
      // margin-right: auto;
      img {
        max-width: 140px;
        margin: 1rem;
      }
      img:not(:last-child) {
        margin-right: 2rem;
      }
    }
  }
  .types {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    margin: 5rem 0 5rem 0;

    .water {
      grid-area: 1 / 1 / 2 / 3;
      margin-right: 4rem;
    }
    .ground {
      grid-area: 2 / 1 / 3 / 3;
      margin-right: 4rem;
    }
    .air {
      grid-area: 3 / 1 / 4 / 3;
      margin-right: 4rem;
    }
    .image {
      grid-area: 1 / 3 / 4 / 4;
      align-self: center;
      img {
        width: 100%;
      }
    }
  }
  .efficiency {
    margin: 6rem 0 6rem 0;

    .title {
      margin-bottom: 0;
    }
    .paragraph {
      margin-top: 1rem;
      max-width: 100%;
      text-align: left;
      // padding: 2rem;
    }
  }
  .logos {
    display: flex;
    flex-direction: row;
    margin: 5rem 0 5rem 0;
    justify-content: space-around;
    flex-wrap: wrap;
    align-content: center;
    img {
      margin: 1rem;
      object-fit: cover;
      height: 55px;
    }
  }
  .twoItemsContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    padding-left: 0;
    padding-right: 0;
    margin-left: 0;
    margin-right: 0;

    .title {
      margin-top: 0;
      font-size: 30px;
    }
    .card {
      display: flex;
      padding: 2.5rem;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      max-width: 550px;
      flex: 1 1 300px;

      .paragraph {
        font-size: 18px;
        color: $secondary;
        line-height: 200%;
        text-align: center;
      }
    }
    img {
      margin-top: 2rem;
      max-width: 124px;
    }
  }

  span.white {
    color: $white;
  }

  .mitsubishiSection {
    margin-top: 10rem;
    margin-bottom: 10rem;
    h3 {
      margin: 0.5rem;
    }
    .paragraph span {
      color: $white;
    }
    .twoItems {
      .paragraph {
        max-width: 400px;
        line-height: 200%;
        text-align: center;
        margin-top: $vertical-padding;
      }
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      flex-wrap: wrap;
      align-items: flex-start;
    }
  }

  .ecodanSection {
    margin-bottom: 10rem;
    h2 {
      margin: 4rem;
    }
    .paragraph {
      // text-align: center;
      margin-top: 0rem
    }
    .ecodanContent {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, 1fr);
      grid-column-gap: 0px;
      grid-row-gap: 0px;
      .text {
        grid-area: 1 / 1 / 3 / 2;
        margin-right: 4rem;
      }
      .image {
        grid-area: 1 / 2 / 3 / 3;
        justify-self: center;
        width: 100%;
        align-self: center;
        display: flex;
        justify-content: center;
        img {
          width: 100%;
          height: auto;
          max-width: 500px;
        }
      }
    }
  }

  .mrslimSection {
    margin-bottom: 6rem;
  }

  @media screen and (max-width: $mlarge) {
  }
  @media screen and (max-width: $large) {
    .types {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(3, 1fr);
      grid-column-gap: 10px;
      grid-row-gap: 10px;
      margin: 5rem 0 5rem 0;

      .water {
        grid-area: 1 / 1 / 2 / 3;
        margin-right: 0;
      }
      .ground {
        grid-area: 2 / 1 / 3 / 3;
        margin-right: 0;
      }
      .air {
        grid-area: 3 / 1 / 4 / 3;
        margin-right: 0;
      }
      .image {
        grid-area: 4 / 1 / 5 / 3;
        align-self: center;
        display: none;
        img {
          width: 100%;
        }
      }
    }

    .twoItemsContainer {
      margin: 0;
      margin-top: 3rem;
      margin-bottom: 3rem;
    }

    .ecodanSection {
      margin-bottom: 10rem;
      h2 {
        margin: 2rem 0 2rem 0;
      }
      .ecodanContent {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-template-rows: repeat(2, 1fr);
        grid-column-gap: 0px;
        grid-row-gap: 0px;
        .text {
          grid-area: 1 / 1 / 3 / 2;
          margin-right: 0;
          margin-bottom: 4rem;
        }
        .image {
          grid-area: 3 / 1 / 4 / 2;
          justify-self: center;
          width: 100%;
          display: flex;
          justify-content: center;
        }
      }
    }
  }
  @media screen and (max-width: $smedium) {

    .imageContainer img,
    .types,
    .efficiency,
    .ecodanSection h2,
    .ecodanSection {
      margin: 2rem 0 2rem 0;
    }
    .mrslimSection {
      margin: 4rem 0 6rem 0;
    }
    .ecodanSection {
      h2 {
        margin: 1rem 0 1rem 0;
      }
    }

    .mitsubishiSection {
      margin: 2rem 0 6rem 0;
    }
    
    .logos {
      img {
        height: 50px;
      }
    }
  }
  @media screen and (max-width: $small) {
    .logos {
      img {
        height: 30px;
      }
    }
  }
}
