@import '../../components/variables.scss';

.shimmer-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    animation: loading 1.5s infinite;

    .shimmer {
        width: 50%;
        height: 100%;
        background: rgba(255, 255, 255, 0.07);
        transform: skewX(-20deg);
        box-shadow: 0 0 30px 30px rgba(255, 255, 255, 0.07);
        ;
    }
}

@keyframes loading {
    0% {
        transform: translateX(-150%);
    }

    50% {
        transform: translateX(60%);
    }

    100% {
        transform: translateX(150%);
    }

}

.productContainer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 40px;
    grid-row-gap: 0px;
}

.skeletonProduct {
    overflow: hidden;
    border-radius: 17px;
    position: relative;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 40px;
    grid-row-gap: 0px;
    height: 150px;
    margin-bottom: 3rem;

    .imgBackground {
        background-color: $grey;
        border-radius: 10px;
        @extend .imageDiv
    }

    .productCardContent {
        display: flex;
        justify-content: space-between;

        .productTitle,
        .productDescription,
        .productBrand,
        .button,
        p {
            border-radius: 10px;
            width: 100%;
            color: $grey !important;
            background-color: $grey !important;
            border: 0;
        }

        .productPrice {}

        .productCardHeader {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 100%;

            &>p,
            div {
                margin: 0;

                // &:last-of-type{
                //     width: 50%;
                // }
            }
        }

        @extend .contentDiv;
    }

}

.imageDiv {
    grid-area: 1 / 1 / 2 / 2;
    border-radius: 17px;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-left: 1rem;
    padding-right: 1rem;
    background-color: $quaternary;


    img {
        // width: 100%;
        // width: auto;
        // height: 100%;
        object-fit: cover;
        border-radius: 10px;
        max-height: 500px;

    }

}

.contentDiv {
    grid-area: 1 / 2 / 2 / 5;
    display: flex;
    flex-direction: column;

    .productTitle {
        font-family: $font-medium;
        color: $white;
        font-size: $large-font !important;
        margin-bottom: 2rem;
        margin-top: 0;
    }

    .productBrand {
        font-family: $font-medium;
        font-size: $mediuml-font;
        margin-bottom: 20px;
        color: $secondary;
        margin-top: 0;

        &>a {
            margin: 0;
        }
    }

    .productDescription {
        margin-bottom: 2rem;
    }

    .buttonContainer {
        margin-top: 1rem;
        display: flex;
        flex-direction: row;
        align-items: center;

        input {
            margin-left: 1.5rem;
            width: 40px;
            background-color: $quaternary;
            outline: none;
            font-family: $font-medium;
            padding: 0.75rem 0.75rem;
            border: 2px $quaternary solid;
            border-radius: 10px;
            color: white;
            text-align: center;
        }
    }

    .productSpecifications {
        width: fit-content;
        margin-bottom: 3rem;

        a {
            color: $secondary;
            text-decoration: underline;
            margin-left: 0;
            font-family: $font-bold;
            display: flex;
            flex-direction: row;
            align-items: center;

            .downloadIcon {
                margin-left: 5px;
                color: $secondary;
                height: 22px;
            }



            &:hover {
                transform: none;
            }
        }
    }

    .productPrice {
        color: $green;
        font-family: $font-bold;
        font-size: $mbigger-font;
        margin-bottom: 0.5rem;
        margin-top: 0;
    }

    .outOfStockPrice {
        color: $grey;
    }

    .productStock {
        margin-top: 0;
        margin-bottom: 3rem;
        font-family: $font-medium;

    }

    .button {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: center;

        .cartIcon {
            margin-left: 5px;
            height: 18px;
        }

        &:hover>img {
            filter: invert(1);
        }

    }
}

.notFound {
    .linksDiv {
        text-align: center;
    }
}

@media screen and (max-width: 768px) {

    .skeletonProduct {
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: 1fr;
        grid-column-gap: 10px;
        height: 100px;
        margin-bottom: 1.5rem;
    
    }
    
    .imageDiv {
        grid-area: 1 / 1 / 2 / 2;
        
    
    }
    
    .contentDiv {
        grid-area: 1 / 2 / 2 / 6;
    
        
    }

    .productCardContent{
        .productCardHeader{
            .productBrand{
                display: none;
            }
        }
    }

}