@import '../../components/variables.scss';

.imageSwiper {

    width: 100%;
    // max-width: 1300px;

    h3 {
        margin-top: 1rem;
        margin-bottom: 2rem;
    }


    .swiper {
        width: 100%;

        .swiper-wrapper {
            width: 0px;
            height: auto;
            max-height: 500px;
        }

        .swiper-button-next,
        .swiper-button-prev {
            right: 10px;
            color: #D4D4D4 !important;
            fill: #D4D4D4 !important;
            stroke: #D4D4D4 !important;
        }

        .swiper-pagination-progressbar-fill {
            background-color: $green;
        }

    }

    .swiper-free-mode {
        // height: inherit;
        margin-top: 10px;

        .swiper-wrapper {
            // width: inherit;
            margin: 0;

            &>.swiper-slide {
                max-height: 180px;

                // height: fit-content;
                :hover {
                    cursor: pointer;
                }

                img {
                    height: 100%;
                    border-radius: 0;
                    object-fit: cover;
                }
            }
        }


    }

    .swiper-slide {
        text-align: center;
        font-size: 18px;
        background: #363636;
        height: auto;
        max-height: 600px;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        position: relative;

        .caption {
            background: rgb(28, 28, 28);
            background: linear-gradient(180deg, rgba(28, 28, 28, 0) 0%, rgba(28, 28, 28, 0.1951374299719888) 25%, rgba(25, 25, 25, 0.5508797268907564) 51%, rgba(20, 20, 20, 0.9150253851540616) 100%);
            display: flex;
            position: absolute;
            bottom: 0;
            left: 0;
            padding-top: 15px;
            padding: 10px;
            text-align: left;
            font-size: 11px;
            color: white;
            line-height: 150%;
            width: 100%;

            h4 {
                padding-right: 10px;
            }
        }

        img {
            transition: all 0.3s ease 0s;
            width: 100%;
        }

        // img:hover {
        //     transform: scale(1.25);
        // }
    }

    .swiper-slide img {
        // display: block;
        // width: 100%;
        // height: 100%;
        // object-fit: cover;
        // position: absolute;
        // top: 50%;
        // left: 50%;
        // transform: translate(-50%, -50%);
        object-fit: contain;
        max-width: 100%;
        max-height: 100%;
    }

    .projectDescription {
        margin-left: auto;
        display: flex;
        flex-wrap: wrap;
        margin-right: auto;
        flex-direction: column;
        align-content: flex-start;
        justify-content: center;
        align-items: flex-start;
        max-width: 1000px;
        width: 100%;
        margin-top: 1rem;

        .referenceSpan {
            color: $secondary !important;
        }

        &>.subtitle {

            line-height: 180%;

        }
    }

}

@media screen and (min-width:$larger) {
    .imageSwiper {
        .swiper .swiper-wrapper {
            max-height: 1000px;
        }
        .swiper-slide {
            max-height: 800px;
        }
    }
}