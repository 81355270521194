@import '../../components/variables.scss';

.solar {
  padding-top: $navbar-height;

  .title {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }
  .solarCard {
    margin-left: auto;
    margin-right: auto;
    background-color: transparent;
    border: 6px solid $mediumGrey;
    max-width: 1060px;
  }
  .subtitle {
    line-height: 200%;
  }
  p .items .item {
    .title {
      margin-bottom: inherit;
    }
    grid-template-areas:
      'title'
      'description'
      'icon';

    & > .title {
      margin-top: 0;
      text-align: center;
    }
    & > .icon {
      margin-top: 1rem;
    }
    & > .description {
      text-align: center;
      margin-top: 0;
    }
  }

  .productsLink {
    // display: flex;
    // justify-content: space-evenly;
    // margin-top: $horizontal-padding;
    // flex-wrap: wrap;
  }

  .solarText {
    margin-top: 4rem;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: center;
    .subtitle {
      line-height: 200%;
    }
  }

  .installationDiv, .carportImg {
    margin-bottom: 3rem;
    margin-top: 3rem;
    .subtitle {
      // font-size: $large-font;
    }
    img {
      width: 100%;
      max-width: 1100px;
    }
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .carportImg {
    &>img{
      border-radius: 17px;
      height: 400px;
      object-fit: cover;
    }
  }

  .schemeContainer {
    // margin-top: 4rem;
    display: grid;
    padding: 2rem;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 50px 40px;
    grid-auto-flow: row;
    grid-template-areas:
      'schemeImage schemeDescription'
      'schemeImage1 schemeDescription1';

    .schemeImage {
      grid-area: schemeImage;
      align-self: center;
      justify-self: center;
      img {
        // max-width: 10%;
        object-fit: cover;
      }
    }

    .schemeDescription {
      grid-area: schemeDescription;
      justify-self: center;
      align-self: flex-start;
    }
    .schemeImage1 {
      grid-area: schemeImage1;
      justify-self: center;
      align-self: center;
    }

    .schemeDescription1 {
      grid-area: schemeDescription1;
      align-self: flex-start;
    }
    .paragraph {
      line-height: 200%;
      text-align: justify;
    }
  }
  .hybridContainer {
    display: grid;
    padding: 2rem;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    gap: 20px 40px;
    grid-auto-flow: row;
    grid-template-areas:
      'schemeTitle'
      'schemeImage'
      'schemeDescription';
    align-items: center;
    justify-items: center;

    .schemeImage {
      grid-area: schemeImage;
      // justify-self: center;
      // align-self: center;
      img {
        // max-width: 10%;
        object-fit: cover;
      }
    }

    .schemeDescription {
      grid-area: schemeDescription;
      justify-self: center;
      align-self: flex-start;
    }

    .paragraph {
      line-height: 200%;
      text-align: justify;
      max-width: 900px;
    }
  }

  .titleEquipment{
    .paragraph{
      max-width: 600px;
    }
  }
  .equipmentButton{
    margin-top: 1rem;
  }
  .items {
    display: grid;
    grid-template-columns: 1fr 1fr;
    // gap: 3rem;
    margin: 4rem;
    // align-items: end;
    justify-items: stretch;
    grid-column-gap: 2rem;

    .subtitle {
      line-height: 120%;
    }

    .item {
      height: 100%;
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: 1fr;
      gap: 0px 0px;
      justify-items: center;
      grid-template-areas:
        'title'
        'description';
      border: 0;

      .icon {
        grid-area: icon;

        .iconStyle {
          width: 5rem;
          object-fit: cover;
        }
      }
      .title {
        grid-area: title;
        margin-top: 1.5rem;
        max-width: none;
      }
      .description {
        grid-area: description;
        margin-top: 0.5rem;
        font-family: 'Raleway-Medium';
        text-align: center;
        max-width: none;
      }
    }
  }

  @media screen and (max-width: $mlarge) {
    .schemeContainer {
      img {
        max-width: 400px;
      }
    }
    .hybridContainer {
      img {
        max-width: 500px;
      }
    }
  }

  @media screen and (max-width: $large) {
    .schemeContainer {
      grid-template-columns: 1fr;
      grid-template-rows: 1fr;
      grid-template-areas:
        'schemeImage'
        'schemeDescription'
        'schemeImage1'
        'schemeDescription1';

      img {
        max-width: 400px;
      }
    }
    .hybridContainer {
      grid-template-areas:
        'schemeTitle'
        'schemeImage '
        'schemeDescription';
      img {
        max-width: 500px;
      }
    }
    .items {
      display: grid;
      grid-template-columns: 1fr;
      // gap: 3rem;
      margin: 0rem;
      // align-items: end;
      justify-items: stretch;
      grid-column-gap: 2rem;
  
      .subtitle {
        line-height: 120%;
      }
  
      .item {
        height: 100%;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr;
        gap: 0px 0px;
        justify-items: center;
        grid-template-areas:
          'title'
          'description';
        border: 0;
  
        .icon {
          grid-area: icon;
  
          .iconStyle {
            width: 5rem;
            object-fit: cover;
          }
        }
        .title {
          grid-area: title;
          margin-top: 1.5rem;
          max-width: none;
        }
        .description {
          grid-area: description;
          margin-top: 0.5rem;
          font-family: 'Raleway-Medium';
          text-align: center;
          max-width: none;
        }
      }
    }

    .items .item:last-child {
      margin-top: 0; 
      grid-column: inherit;
    }
  }

  @media screen and (max-width: $medium) {
    .schemeContainer {
      img {
        max-width: 300px;
      }
      .paragraph {
        line-height: 170%;
      }
    }
    .hybridContainer {
      img {
        max-width: 300px;
      }
      .paragraph {
        line-height: 170%;
      }
    }
  }

  @media screen and (max-width: $smedium) {
    .schemeContainer {
      img {
        max-width: 280px;
      }
    }
    .hybridContainer {
      img {
        max-width: 280px;
      }
    }
  }

  @media screen and (max-width: $small) {
    .schemeContainer {
      img {
        max-width: 250px;
      }
    }
    .hybridContainer {
      img {
        max-width: 250px;
      }
    }
  }
}
