@import '../../components/variables.scss';

.notFound {
  height: 100%;
  margin-bottom: auto;
  // margin-top: $navbar-height;
  text-align: center;
  min-height: $min-content-height;
  padding-top: $navbar-height;


  .title:first-child {
    margin-top: 3rem;
  }
  img {
    width: 100%;
    max-width: 500px;
    min-width: 100px;
    margin-top: 3rem;
    animation: bounce 2s infinite alternate-reverse ease-in-out;
  }

  p {
    line-height: 200%;
  }

  .linksDiv {
    grid-area: linksDiv;
    display: grid;
    margin-left: 0;
    column-gap: 15px;
    text-align: left;
    margin-top: 1rem;
      .linksDivTitle {
      grid-area: 1 / 1 / 2 / 4;
    }
  
    .homeLinks {
      grid-area: 2 / 1 / 3 / 2;
    }
  
    .productsLinks {
      grid-area: 2 / 2 / 3 / 3;
    }
  
    .servicesLinks {
      grid-area: 2 / 3 / 3 / 4;
    }
  }
}
