@import '../../components/variables.scss';

.skeletonCard {
  overflow: hidden;
  border-radius: 17px;

  .productCard {
    position: relative;

    .imgBackground {
      background-color: $grey;
      border-radius: 10px;
    }

    .productTitle,
    .productDescription,
    .productBrand,
    .button {
      border-radius: 10px;
      width: 100%;
      color: $grey;
      background-color: $grey;
      border: 0;
    }
    
  }
}

.productCard {
  max-width: 180px;
  display: flex;
  flex-direction: column;
  background-color: $tertiary;
  border-radius: 17px;
  padding: 1rem;
  width: 100%;




  .shimmer-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    animation: loading 1.5s infinite;

    .shimmer {
      width: 50%;
      height: 100%;
      background: rgba(255, 255, 255, 0.07);
      transform: skewX(-20deg);
      box-shadow: 0 0 30px 30px rgba(255, 255, 255, 0.07);
      ;
    }
  }

  @keyframes loading {
    0% {
      transform: translateX(-150%);
    }

    50% {
      transform: translateX(60%);
    }

    100% {
      transform: translateX(150%);
    }

  }

  .paragraph>span {
    font-family: 'Roboto-Bold';
    color: $secondary !important;
  }

  .productCardContent {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }

  .productBrand {
    margin-bottom: 10px;
    font-family: $font-bold;
    color: $mediumGrey;
    font-size: $small-font;
    text-align: center;
  }

  .productTitle {
    margin-top: 0;
    font-size: $medium-font;
    margin-bottom: 5px;
    text-align: center;
    word-wrap: break-word;
    // min-height: 75px;
    &:hover {
      cursor: pointer;
    }
  }

  .productDescription {
    margin-bottom: 1rem;
    font-size: $small-font;
  }

  .productSpecifications {
    margin-top: 1rem;
    margin-bottom: 1rem;

    &>.paragraph {
      line-height: 200%;
    }
  }

  .productCardFooter {
    display: flex;
    flex-direction: column;
  }

  .productPrice {
    margin-top: 1rem;
    text-align: center;
    color: $green;
    font-family: 'Roboto-bold';

  }

  .outOfStockPrice {
    color: $grey;
  }



  .noImgBg {
    background-color: $grey;
    max-width: 390px;
    max-height: 390px;
    height: 100%;
    z-index: 10;
  }

  .imgBackground {
    border-radius: 20px;
    min-height: 220px;
    margin-bottom: 1rem;
    max-width: 390px;
    max-height: 210px;
    background-color: white;
    overflow: hidden;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    img {
      width: 100%;
      border-radius: 10px;
      max-width: 390px;
      object-fit: cover;
      z-index: 9;
      max-width: 390px;
      // height: 260px;
      position: relative;
      &:hover {
        cursor: pointer;
      }
    }
  }

  .placeholderBg {
    background-color: #949494;
  }

  a {
    width: 100%;
    text-align: center;
    margin: 0;
    .button {
      // margin-top: 1rem;
      width: 100%;
      margin-top: auto;
      margin: 0;
    }
  }

  @media screen and (max-width: $large) {
    .productTitle {
      font-size: $medium-font;
    }

    .productDescription {
      font-size: $small-font;
    }

    .productSpecifications {
      font-size: $small-font;
    }
  }
}