@import '../variables.scss';

.modal {
  // position: absolute;
  margin-top: $navbar-height;
  background-color: $tertiary;
  z-index: 101;
  width: 80%;
  border-radius: 17px;
  padding: 28px;
  // overflow: scroll;
  // height: 82vh;
  max-height: 650px;
  max-width: 900px;
  overflow: scroll;
  position: relative;
  outline: none;

  .closeIconContainer {
    position: absolute;
    top: 20px;
    right: 20px;

    .closeIcon {
      width: 16px;
      display: block;
      margin-left: auto;
      cursor: pointer;
    }
  }

  .formContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: 0.35s ease-in-out;

    .confirmation {
      transition: 0.35s ease-in-out;
      width: 80%;
      height: 45vh;
      background-color: $primary;
      padding: 2rem;
      border-radius: 17px;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-content: center;
      align-items: center;

      .subtitle {
        margin-top: 1rem;
      }

      img {
        animation: pop 1s ease-in-out both;
      }
    }

    form {
      .paragraph.error {
        margin-top: inherit;
      }
    }

    .enquiryProduct {
      font-size: $mediuml-font;
      color: $secondary;
      font-family: $font-medium ;
    }

    .emailForm {
      width: 80%;

      input[type='text'],
      input[type='email'],
      select,
      textarea {
        width: 100%;
        padding: 12px;
        border: 1px solid #ccc;
        border-radius: 7px;
        border-color: #6262628f;
        background-color: transparent !important;
        box-sizing: border-box;
        margin-top: 6px;
        margin-bottom: 2rem;
        resize: vertical;
        color: #f0f0f0;
      }

      input:focus,
      textarea:focus {
        outline: none !important;
        border-color: #9d9d9d8f !important;
        -webkit-box-shadow: 0px 0px 5px 2px rgba(206, 206, 206, 0.24);
        -moz-box-shadow: 0px 0px 5px 2px rgba(206, 206, 206, 0.24);
        box-shadow: 0px 0px 5px 2px rgba(206, 206, 206, 0.24);
      }

      #selectedProduct:hover {
        background-color: #f0f0f0;
      }
    }

    label {
      color: $secondary;
    }

    input[type='submit'] {
      background-color: transparent;
      appearance: auto;
      user-select: none;
      white-space: pre;
      align-items: flex-start;
      text-align: center;
      cursor: default;
      box-sizing: border-box;
      color: white;
      padding: 0;
      border: 0;
    }
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #00000093;
  z-index: 101;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 200ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}