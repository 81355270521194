@import '../../components/variables.scss';

.login{
    // margin-top: $navbar-height;
    margin-bottom: auto;
    min-height: $min-content-height;
    display: flex;
    padding-top: $navbar-height;

    
    
    .formContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        transition: 0.35s ease-in-out;
    
        .confirmation {
          transition: 0.35s ease-in-out;
    
          .mailbox {
            width: 12vh;
            
          }
          width: 80%;
          height: 45vh;
          background-color: $primary;
          padding: 2rem;
          border-radius: 17px;
          display: flex;
          justify-content: space-evenly;
                flex-direction: column;
          align-content: center;
          align-items: center;
        }
    
        .emailForm {
          width: 80%;
          input[type='text'],
          input[type='password'],
          select,
          textarea {
            width: 100%;
            padding: 12px;
            border: 1px solid #ccc;
            border-radius: 7px;
            border-color: #6262628f;
            background-color: transparent !important;
            box-sizing: border-box;
            margin-top: 6px;
            margin-bottom: 2rem;
            resize: vertical;
            color: #f0f0f0;
          }
          input:focus,
          textarea:focus {
            outline: none !important;
            border-color: #9d9d9d8f !important;
            -webkit-box-shadow: 0px 0px 5px 2px rgba(206, 206, 206, 0.24);
            -moz-box-shadow: 0px 0px 5px 2px rgba(206, 206, 206, 0.24);
            box-shadow: 0px 0px 5px 2px rgba(206, 206, 206, 0.24);
          }
        }
    
        input[type='submit'] {
          background-color: transparent;
          appearance: auto;
          user-select: none;
          white-space: pre;
          align-items: flex-start;
          text-align: center;
          cursor: default;
          box-sizing: border-box;
          color: white;
          padding: 0;
          border: 0;
        }
      }
    
}