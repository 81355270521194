@import '../variables.scss';

.button {
  background-color: transparent;
  padding: 0.75rem 1.5rem;
  border: 2px $white solid;
  border-radius: 10px;
  color: $color-nav-link;
  font-size: 14px;
  // font-family: $font-medium;
  font-family: $font-bolder;
  transition: transform 0.35s ease-in;

  &-rounded {
    @extend .button;
    border-radius: 17px;
  }

  &-ghost {
    border: 2px #ffffff3b solid;
  }

  &-green {
    background-color: $green;
    color: $primary;
    min-width: 145px;
    border: 0;
    
  }

  &-white {
    background-color: $white;
    color: $primary;
    img{
      filter: invert(1);
    }
  }

  &-categoryButton {
    background: linear-gradient(180deg, #91ce3b 0%, rgba(59, 206, 144, 0.88) 78.65%);
    color: $quaternary;
  }

  &-mini {
    @extend .button;
    padding: 0.5rem 1rem;
    border-radius: 17px;
    margin-top: 0.75rem !important;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 70px;
  }

  &-wide {
    min-width: 180px;
  }

  &-image {
    @extend .button;
    padding: 0.5rem 1rem;
    border-radius: 17px;
    margin-top: 0.75rem !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-width: 200px;
    max-width: 200px;
    height: 140px;


    &>img {
      min-width: 60px;
      min-height: 60px;
      max-width: 60px;
      max-height: 60px;
      margin-bottom: 0.5rem;


    }
  }

  &:hover {
    -webkit-box-shadow: 0px 0px 12px 0px rgb(167, 167, 167);
    -moz-box-shadow: 0px 0px 12px 0px rgb(167, 167, 167);
    box-shadow: 0px 0px 12px 0px rgb(167, 167, 167);
    transform: scale(1.05);
    background-color: #ffffff;
    color: black;
    cursor: pointer;

    &>input {
      color: black !important;
    }

    &>a {
      color: black;
    }
  }

  &>a {
    color: $white;
    margin-bottom: -1px;
    transition: all 0.35s ease-in-out;
    font-family: $font-bolder;
  }

  &:hover>a {
    color: black;
  }
}