@import '../../components/variables.scss';

.diagnostics {
    padding-top: $navbar-height;

    .title {
        margin-top: 3rem;
    }

    .diagnostics-image {
        // margin-top: 3rem;
        margin-bottom: 4rem;
        width: 100%;
        border-radius: 10px;
        margin-left: auto;
        margin-right: auto;
        display: block;
        min-width: 200px;
        max-width: 800px;
    }

    .diagnosticsIntroText {
        margin-top: 2rem;
        margin-bottom: 2rem;
        display: flex;
        flex-direction: column;
        align-content: center;
        justify-content: center;
        align-items: center;

        .subtitle {
            line-height: 200%;
        }
    }

    .title{
        margin-top: 3rem;
    }

    .subtitle {
        line-height: 200%;
    }

    .points {
        ul {
            color: #a1a1a1 !important;
        }
    }

    .projectTypes {
        color: $secondary;

        .paragraph {
            line-height: 200%;

        }
    }
}

.rental {
    .diagnosticsIntroText {
        align-items: flex-start;
    }

    .paragraph {
        margin-top: 1rem;
        margin-bottom: 1rem;
    }

    .videoPlayer {
        width: 100% !important;
        height: 100% !important;

    }
}