// @font-face {
//   font-family: 'DMSans';
//   src: local('DMSans-Regular'), url(./assets/fonts/DM_Sans/DMSans-Regular.ttf) format('truetype');
// }
// @font-face {
//   font-family: 'DMSans';
//   src: local('DMSans-Medium'), url(./assets/fonts/DM_Sans/DMSans-Medium.ttf) format('truetype');
//   font-weight: 500;
// }
// @font-face {
//   font-family: 'DMSans-Bold';
//   src: local('DMSans-Bold'), url(./assets/fonts/DM_Sans/DMSans-Bold.ttf) format('truetype');
//   font-weight: bold;
// }
@import './components/variables.scss';

@font-face {
  font-family: 'Roboto';
  src: local('Roboto-Regular'), url(../assets/fonts/Roboto-Regular.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto-Medium';
  src: local('Roboto-Medium'), url(../assets/fonts/Roboto-Medium.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto-Bold';
  src: local('Roboto-Bold'), url(../assets/fonts/Roboto-Bold.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto-Black';
  src: local('Roboto-Black'), url(../assets/fonts/Roboto-Black.ttf) format('truetype');
}
@font-face {
  font-family: 'Raleway';
  src: local('Raleway-Regular'), url(../assets/fonts/Raleway-Regular.ttf) format('truetype');
}
@font-face {
  font-family: 'Raleway-Medium';
  src: local('Raleway-Medium'), url(../assets/fonts/Raleway-Medium.ttf) format('truetype');
}
@font-face {
  font-family: 'Raleway-SemiBold';
  src: local('Raleway-SemiBold'), url(../assets/fonts/Raleway-SemiBold.ttf) format('truetype');
}
@font-face {
  font-family: 'Raleway-Bold';
  src: local('Raleway-Bold'), url(../assets/fonts/Raleway-Bold.ttf) format('truetype');
}
@font-face {
  font-family: 'Raleway-ExtraBold';
  src: local('Raleway-ExtraBold'), url(../assets/fonts/Raleway-ExtraBold.ttf) format('truetype');
}

body {
  max-width: 1900px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0;
  margin-bottom: 0;
  background-color: #050505;
}

.title > span,
.subtitle > span,
.paragraph > span,
li > span {
  font-family: $font-bold;
  color: $green;
}
