@import '../variables.scss';

.contactWidget {
    background-color: $primary;
    // padding: 20px;
    border-radius: 5px;
    box-shadow: 0 0 20px rgb(0 0 0 / 41%);
    position: fixed;
    bottom: 0;
    margin: 20px;
    right: 10px;
    max-width: 400px;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    border-radius: 17px;
    z-index: 90;
    opacity: 0;
    right: 10px;
    /* Off-screen */
    transition: transform 0.5s ease-in-out, right 0.5s ease-in-out;

    &.visible {
        animation: slideInBounce 1.6s ease-in-out forwards;
        opacity: 1;
    }

    &.hidden {
        animation: slideOutBounce 1s ease-out forwards;
        // opacity: 1;
      }

    &>.closeButton {
        z-index: 92;
        position: absolute;
        top: 0;
        right: 0;

        img {
            width: 10px;
            cursor: pointer;
            padding: 10px;
            background-color: #0f0f0fed;
            border-radius: 100%;
            margin: 10px
        }

    }





    &>.imgDiv {
        position: relative;

        &>img {
            width: 100%;
            object-fit: cover;
            border-top-left-radius: 17px;
            border-top-right-radius: 17px;
            max-height: 155px;
            z-index: 90;

        }

        &>.imgDivOverlay {

            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgb(20 20 20 / 86%) 50%, rgb(20 20 20) 100%);
            z-index: 91;
        }

        &>.titleDiv {
            display: flex;
            flex-direction: column;
            padding: 20px;
            padding-top: 10px;
            z-index: 92;
            bottom: 0;
            position: absolute;
            z-index: 95;

            &>* {
                margin: 0;
            }

            .subtitle {
                font-size: 18px;
                margin-bottom: 10px;
            }

            .paragraph {
                font-size: 14px;
            }
        }



    }


    &>.buttonsDiv {
        background-color: $primary;
        display: inline-flex;
        flex-direction: row;
        justify-content: space-evenly;
        padding: 20px;
        border-bottom-left-radius: 17px;
        border-bottom-right-radius: 17px;

        &>.button {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;

            a {
                all: unset;
            }

            &:hover{
                &>img{
                    filter: invert(1);
                }
            }

            &>img {
                height: 15px;
                width: auto;
                margin-right: 10px;

            }
        }

    }
}



@media screen and (max-width: $smedium) {

    .contactWidget {
        right: 0;
        left: 0;
        max-width: 400px;
        // max-width: none;
        bottom: 0;
        width: 90%;
        position: fixed;

        .button{
            margin: 0.5rem;
            a {
                all: unset;
            }

            &:hover{
                img{
                    filter: invert(1);
                }
            }
        }

        &>.closeButton {


            img {
                margin: 5px;
            }

        }





        &>.imgDiv {
            height: min-content;

            &>img {
                max-height: 130px;

            }

            &>.imgDivOverlay {

                background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgb(5 5 5 / 76%) 50%, rgb(15 15 15) 100%);
            }

            &>.titleDiv {
                bottom: 5px;
                /* padding-top: 30px; */
                padding-right: 40px;

                &>* {}

                .subtitle {
                    font-size: 15px;
                }

                .paragraph {}
            }



        }


        &>.buttonsDiv {
            padding: 0;

            &>.button {

                &>img {}
            }

        }
    }
}

@keyframes slideInBounce {
    0% {
        right: -400px;
        /* Off-screen */
    }

    80% {
        right: 30px;
        /* Slight overshoot */
    }

    100% {
        right: 0px;
        /* Final position */
        transform:none;
    }
}

@keyframes slideOutBounce {
    0% {
        right: 20px;
        /* Starting position */
        // opacity:1
    }

    20% {
        right: 30px;
        /* Slight overshoot */
    }

    100% {
        right: -400px;
        /* Off-screen */
        // opacity:0
    }
}