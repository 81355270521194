@import '../../components/variables.scss';

.productPage {
    padding-top: $navbar-height;

    .shimmer-wrapper {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        animation: loading 1.5s infinite;

        .shimmer {
            width: 50%;
            height: 100%;
            background: rgba(255, 255, 255, 0.07);
            transform: skewX(-20deg);
            box-shadow: 0 0 30px 30px rgba(255, 255, 255, 0.07);
            ;
        }
    }

    @keyframes loading {
        0% {
            transform: translateX(-150%);
        }

        50% {
            transform: translateX(60%);
        }

        100% {
            transform: translateX(150%);
        }

    }

    .backButton {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-content: center;
        align-items: center;
        margin-bottom: 2rem;
        transition: 0.25s ease-in-out;
        width: fit-content;

        img {
            // width: 100%;
            height: 15px;
            margin-right: 10px;
        }

        &:hover {
            color: #f0f0f0;
            text-shadow: 1px 0 0 #f6f6f6;
            -webkit-text-stroke-width: 0.5px;
            transform: scale(1.05);
            transition: 0.25s ease-in-out;
        }
    }

    .productContainer {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: 1fr;
        grid-column-gap: 40px;
        grid-row-gap: 0px;
    }

    .skeletonProduct {
        overflow: hidden;
        border-radius: 17px;
        position: relative;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: 1fr;
        grid-column-gap: 40px;
        grid-row-gap: 0px;
        height: 400px;
        margin-bottom: 3rem;

        .imgBackground {
            background-color: $grey;
            border-radius: 10px;
            @extend .imageDiv
        }

        .productCardContent {
            display: flex;
            justify-content: space-between;

            .productTitle,
            .productDescription,
            .productBrand,
            .button,
            p {
                border-radius: 10px;
                width: 100%;
                color: $grey !important;
                background-color: $grey !important;
                border: 0;
            }

            .productPrice {}

            @extend .contentDiv;
        }

    }

    .imageDiv {
        grid-area: 1 / 1 / 2 / 2;
        border-radius: 17px;
        background-size: cover;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-left: 1rem;
        padding-right: 1rem;
        padding: 2rem;


        img {
            width: 100%;
            // width: auto;
            // height: 100%;
            object-fit: contain;
            border-radius: 10px;
            max-height: 400px;

        }

    }

    .contentDiv {
        grid-area: 1 / 2 / 2 / 3;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .productTitle {
            font-family: $font-medium;
            color: $white;
            font-size: $large-font !important;
            margin-bottom: 0;
            margin-top: 0;
        }

        .productRef {
            margin-bottom: 2rem;
            color: #595959;
            ;

        }

        .productBrand {
            font-family: $font-medium;
            font-size: $mediuml-font;
            margin-bottom: 20px;
            color: $secondary;
            margin-top: 0;

            &>a {
                margin: 0;
            }
        }

        .productDescription {
            margin-bottom: 2rem;

            span {
                color: $white;
            }

            &:first-of-type {
                margin-bottom: 1rem;
            }
        }

        .buttonContainer {
            margin-top: 1rem;
            display: flex;
            flex-direction: row;
            align-items: center;

            .productQuantity {
                display: flex;
                flex-direction: row;
                align-items: center;

                input {
                    margin-left: 0;
                    width: 40px;
                    background-color: $quaternary;
                    outline: none;
                    font-family: $font-medium;
                    padding: 0.75rem 0.75rem;
                    border: 2px $quaternary solid;
                    border-radius: 10px;
                    color: white;
                    text-align: center;
                }

                input[type=number]::-webkit-inner-spin-button,
                input[type=number]::-webkit-outer-spin-button {
                    -webkit-appearance: none;
                    margin: 0;
                }

                img {
                    height: 20px;
                    width: 15px;
                    margin-right: 1rem;
                    margin-left: 1rem;
                    transition: transform 0.2s ease-in-out;

                    &:hover {
                        transform: scale(1.35);
                        transition: transform 0.2s ease-in-out;
                        cursor: pointer;


                    }
                }
            }
        }

        .productSpecifications {
            width: fit-content;
            margin-bottom: 3rem;

            a {
                color: $secondary;
                text-decoration: underline;
                margin-left: 0;
                font-family: $font-bold;
                display: flex;
                flex-direction: row;
                align-items: center;

                .downloadIcon {
                    margin-left: 5px;
                    color: $secondary;
                    height: 22px;
                }



                &:hover {
                    transform: none;
                }
            }
        }

        .productPrice {
            color: $green;
            font-family: $font-bold;
            font-size: $mbigger-font;
            margin-bottom: 0.5rem;
            margin-top: 0;
        }

        .outOfStockPrice {
            color: $grey;
        }

        .productStock {
            margin-top: 0;
            margin-bottom: 3rem;
            font-family: $font-medium;

        }

        .button {
            display: flex;
            align-items: center;
            width: 100%;
            justify-content: center;
            margin-right: 1rem;

            .cartIcon {
                margin-left: 5px;
                height: 18px;
            }

            &:hover>img {
                filter: invert(1);
            }

        }
    }

    .notFound {
        .linksDiv {
            text-align: center;
        }
    }


}

@media screen and (min-width: 1400px) and (max-width:1700px) {
    .productPage {
        .imageDiv {
            img {
                max-width: 5600px;
            }
        }
    }
}


@media screen and (max-width: 768px) {
    .productPage {
        padding-top: 7rem;

        .skeletonProduct {
            grid-template-columns: 1fr;
            grid-template-rows: repeat(2, 1fr);
            height: 100%;

            .imgBackground {
                height: 300px;
                border-radius: 10px;
                margin-bottom: 10px
            }

            .productCardContent {


                .productTitle,
                .productDescription,
                .productBrand,
                .button,
                p {}

                .productPrice {
                    display: none;
                }

                @extend .contentDiv;
            }

        }

        .productContainer {
            display: grid;
            grid-template-columns: 1fr;
            grid-template-rows: repeat(2, 1fr);
            grid-column-gap: 40px;
            grid-row-gap: 0px;


        }


        .imageDiv {
            grid-area: 1 / 1 / 2 / 2;



            img {
                // width: 85%;
            }

        }

        .contentDiv {
            grid-area: 2 / 1 / 3 / 2;
            display: flex;
            flex-direction: column;

            .productTitle {
                text-align: center;
                margin-bottom: 2rem;
            }

            &>.productInfo>h3>.paragraph {
                text-align: center;
            }

            .productBrand {
                margin-top: 2rem;
                text-align: center;
                margin-bottom: 0;
            }

            .productDescription {
                text-align: center;
                margin-bottom: 1rem;
            }

            .buttonContainer {
                display: flex;
                flex-direction: column-reverse;
                justify-content: center;
                width: 100%;
                align-items: center;

                input {
                    margin-left: 1rem;
                }

                .button {
                    margin-top: 2rem;
                    margin-right: 0;
                }
            }

            .productSpecifications {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-bottom: 1rem;

                a {
                    margin-right: 0;
                    text-align: center;

                    .downloadIcon {}



                    &:hover {}
                }
            }

            .productPrice {
                text-align: center;
                margin-bottom: 0;
            }

            .outOfStockPrice {}

            .productStock {
                text-align: center;
                margin-bottom: 1rem;
            }

            .button {
                margin-left: 0;
                width: 200px;

                .cartIcon {}

                &:hover>img {}

            }
        }


    }
}