@import './components/variables.scss';
@import './components/button/button.style.scss';
@import './components/navbar/navbar.style.scss';
@import './components/enquiryModal/enquiryModal.style.scss';

.App {
  display: flex;
  flex-direction: column;
  background-color: #050505;
  font-size: 1em;
  min-height: 100vh;
  align-items: center;
  padding-top: $navbar-height;

  .videoPreload {
    display: none
  }

  .declineCookieButton {
    @extend .button;
    margin: 1rem !important;
  }

  .cookieButton {
    margin: 1rem !important;
  }

  .CookieConsent {
    a {
      text-decoration: underline;
      font-weight: 500;
    }
  }
}

body {
  // margin: 0;
  // padding: 0;

}

* {
  font-family: 'Roboto';

  a {
    &:visited {
      color: $color-nav-link;
    }

    &:link {
      color: $color-nav-link;
      text-decoration: none;
    }
  }
}

// .container {
//   text-align: center;
//   margin: 1rem;
//   display: flex;
//   flex-direction: column;
//   align-content: center;
//   justify-content: center;
//   align-items: center;

//   &__comingSoonText {
//     display: flex;
//     flex-direction: row;
//     font-size: 6em;
//     color: white;
//     margin-bottom: 1rem;
//     margin-top: 1rem;
//     font-family: 'Roboto-Bold';
//   }

//   &__logo {
//     width: 40%;
//     margin-bottom: 3rem;
//   }

//   &__description {
//     font-size: 1em;
//     font-family: 'Roboto-Medium';
//     color: white;
//   }

//   &__logoContainer {
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     padding-top: 12rem;
//     &__logo1 {
//       height: auto;
//       max-height: 1rem;
//       margin-right: 1rem;
//       object-fit: fill;

//     }
//     &__logo2 {
//       object-fit: fill;
//       max-height: 1rem;
//        }
//     &__part{
//       margin-right: 1rem;
//       color: white;
//     }
//   }
// }

// @media only screen and (max-width: 1700px) {
//   .container {
//     &__comingSoonText {
//       font-size: 5em;
//     }

//     &__logo {
//       width: 30%;
//       margin-bottom: 3rem;
//     }
//   }
// }

// @media only screen and (max-width: 1100px) {
//   .container {
//     &__comingSoonText {
//       font-size: 3em;
//     }

//     &__logo {
//       width: 40%;
//       margin-bottom: 1rem;
//     }
//   }
// }

// @media only screen and (max-width: 600px) {
//   .container {
//     &__comingSoonText {
//       font-size: 2em;
//     }
//   }
// }



.loading {
  margin-left: 1rem;
}

.loading span {
  width: 15px;
  height: 15px;
  margin: 0 5px;
  background-color: white;
  border-radius: 50%;
  display: inline-block;
  animation: dots 2s infinite ease-in-out;
}

.loading span:nth-child(2) {
  background-color: white;
  animation-delay: 0.4s;
}

.loading span:nth-child(3) {
  background-color: white;
  animation-delay: 0.8s;
}

@keyframes dots {
  50% {
    opacity: 0;
    transform: scale(0.7) translateY(10px);
  }
}

h1,
h2,
h3,
h4 {
  margin: 0;
  font-size: inherit;
  font-weight: initial;
  font-family: inherit;
}