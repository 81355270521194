@import '../../components/variables.scss';

.equipment {
  display: flex;
  margin-bottom: auto;
  min-height: $min-content-height;
  align-content: flex-start;
  width: 100%;
  justify-content: flex-start;
  flex-direction: column;
  padding-top: $navbar-height;

  .categoryContainer {
    a {
      margin: 0 !important;

      &:hover{
        transform: scale(1) ;
      }
    }

    display: flex;
    flex-wrap: wrap;
    margin-top: $vertical-padding +2;
    margin-bottom: $vertical-padding;

    .button:not(last-child) {
      margin-right: 1rem;
    }

    .button {
      margin-top: 1rem;
    }
  }

  .productCardContainer {
    margin-bottom: $vertical-padding;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: $vertical-padding;
    justify-items: center;
  }
}

@media screen and (min-width: $larger) {
  .equipment {
    .productCardContainer {
      margin-top: 2rem;
      display: grid;
      grid-template-columns: repeat(7, 1fr);
      grid-gap: $vertical-padding;
    }
  }
}

@media screen and (max-width: $large) {
  .equipment {
    .productCardContainer {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: $vertical-padding;
    }
  }
}

@media screen and (max-width: $lmedium) {
  .equipment {
    .categoryContainer {

      .button {
        min-width: 0 !important;
        margin-top: 0.5rem !important;
        margin-left: 0 !important;

      }
    }
  }
}

@media screen and (max-width: $lmedium) {
  .equipment {
    .productCardContainer {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: $vertical-padding;
      justify-items: center;
    }

  }
}

@media screen and (max-width: $smedium) {
  .equipment {
    .productCardContainer {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      grid-gap: $vertical-padding;
      justify-items: center;
    }
  }
}