@import '../../components/variables.scss';

.about {
  display: flex;
  //   margin-top: $navbar-height;
  margin-bottom: auto;
  min-height: $min-content-height;
  padding-top: $navbar-height;

  .text {
    margin-top: 2rem;
    padding-right: $horizontal-padding;
    padding-left: $horizontal-padding;
    height: 100%;
    padding-bottom: 3rem;

    p {
      line-height: 200%;
    }
  }

  footer {
    position: absolute;
  }
  @media screen and (max-width: $larger) {
    .text {
      margin-top: 2rem;
      padding-right: 1rem;
      padding-left: 1rem;
      height: 100%;
  
      p {
        line-height: 200%;
      }
    }
  }
}
