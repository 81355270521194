@import '../variables.scss';

.footer {
  background-color: $tertiary;
  bottom: 0;
  width: 100vw;
  display: grid;
  grid-auto-columns: 1fr;
  grid-template-columns: 1.5fr 0.5fr 0.5fr 1fr 0.5fr;
  grid-template-rows: 1.7fr 0.3fr;
  gap: 0px 0px;
  grid-template-areas:
    'logoDiv contactDiv addressDiv linksDiv languageDiv'
    'copyrightDiv copyrightDiv copyrightDiv copyrightDiv copyrightDiv';

  color: white;
  padding-bottom: $vertical-padding;
  padding-top: $vertical-padding;
  margin-top: auto;

  .paragraph {
    font-size: $xsmall-font !important;
  }

  a:link,
  a:visited {
    color: $white !important;
  }

  h4 {
    margin: 0;
  }
}

.copyrightDiv {
  grid-area: copyrightDiv;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-right: $horizontal-padding;
  margin-left: $horizontal-padding;
  padding-top: $vertical-padding;
  margin-top: $vertical-padding;
  border-top: 1px solid rgba(255, 255, 255, 0.348);

  &>.socials {
    display: flex;
    &>a {
      margin-right: 1rem;

      &>img {
        transition: transform 0.25s ease-in-out;
        height: 40px;
        width: 100%;

        &:last-of-type {
          margin-right: 0;
        }

        &:hover {
          transform: scale(1.1);
          cursor: pointer;
        }
      }
    }
  }
}

.linksDiv {
  grid-area: linksDiv;
  display: grid;
  margin-left: 0;
  column-gap: 15px;

  align-content: flex-start;

  .linksDivTitle {
    grid-area: 1 / 1 / 2 / 4;
  }

  .homeLinks {
    grid-area: 2 / 1 / 3 / 2;
  }

  .productsLinks {
    grid-area: 2 / 2 / 3 / 3;
  }

  .servicesLinks {
    grid-area: 2 / 3 / 3 / 4;
  }
}

.contactDiv {
  grid-area: contactDiv;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-items: flex-start;
  // margin-left: $horizontal-padding;
}

.logoDiv {
  grid-area: logoDiv;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: $horizontal-padding;

  .logo {
    margin-right: 2rem;
    max-width: 300px;
    width: 80%;
    object-fit: cover;
  }
}

.addressDiv {
  grid-area: addressDiv;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-items: flex-start;
}

.languageDiv {
  grid-area: languageDiv;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 4rem;

  &>.button {
    margin: 0;
  }
}

.langIcon {
  width: $smaller-font + 5;
  margin-right: 0.5rem;
}

@media screen and (max-width: $mlarge) {
  .footer {
    grid-auto-columns: 1fr;
    grid-template-rows: 1.7fr 0.3fr;
    grid-template-columns: 0.5fr 0.5fr 1fr 0.5fr;
    grid-template-areas:
      'contactDiv addressDiv linksDiv languageDiv'
      '. logoDiv logoDiv .'
      'copyrightDiv copyrightDiv copyrightDiv copyrightDiv';

    // .paragraph {
    //   font-size: $xsmall-font;
    // }

    .copyrightDiv {}

    .linksDiv {
      // align-self: flex-start;
      // justify-self: center;
    }

    .contactDiv {
      align-self: flex-start;
      justify-self: center;
    }

    .logoDiv {
      align-self: center;
      justify-self: center;
      margin-top: 0.5rem;
    }

    .addressDiv {
      align-self: flex-start;
      justify-self: center;
    }

    .languageDiv {
      align-self: center;
      justify-self: center;
    }
  }
}

@media screen and (max-width: $large) {
  .footer {
    grid-auto-columns: 1fr;
    grid-template-rows: 1.7fr 0.3fr;
    grid-template-columns: 0.5fr 0.5fr 1fr 0.5fr;
    grid-template-areas:
      'contactDiv addressDiv linksDiv languageDiv'
      '. logoDiv logoDiv .'
      'copyrightDiv copyrightDiv copyrightDiv copyrightDiv';
  }

  .copyrightDiv {
    grid-area: copyrightDiv;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-right: $horizontal-padding;
    margin-left: $horizontal-padding;
    padding-top: $vertical-padding;
    margin-top: $vertical-padding;
    border-top: 1px solid rgba(255, 255, 255, 0.348);
  }

  .linksDiv {
    // grid-area: linksDiv;
    // display: flex;
    // flex-direction: column;
    // align-items: flex-start;
    // margin-left: 0;
  }

  .contactDiv {
    grid-area: contactDiv;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-items: flex-start;
    margin-left: $horizontal-padding;
  }

  .logoDiv {
    grid-area: logoDiv;
    display: flex;
    flex-direction: column;
    justify-content: flex-start !important;
    align-items: center;
    margin-left: 0;
    margin-top: 0.75rem;

    .logo {
      width: 80%;
      object-fit: cover;
    }
  }

  .addressDiv {
    grid-area: addressDiv;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-items: flex-start;
  }

  .languageDiv {
    grid-area: languageDiv;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin-right: 4rem;
  }

  .langIcon {
    width: $smaller-font + 5;
    margin-right: 0.5rem;
  }
}

@media screen and (max-width: $medium) {
  .footer {
    grid-template-columns: 0.5fr 1fr 1fr 0.5fr;
    grid-template-rows: 1fr 1fr 0.75fr 0.5fr 0.5fr;
    grid-gap: 20px 0px;
    grid-template-areas:
      '. contactDiv addressDiv .'
      '. linksDiv linksDiv .'
      '. languageDiv languageDiv .'
      '. logoDiv logoDiv .'
      ' copyrightDiv copyrightDiv copyrightDiv copyrightDiv';

    .paragraph {
      font-size: $mediuml-font;
    }
  }

  .copyrightDiv {
    flex-direction: column;
    align-items: center;
    text-align: center;

    * {
      margin-bottom: 0.5rem;
    }
  }

  .linksDiv {
    // align-items: center;
    text-align: center;
    // margin-left: 0;
    // justify-content: center;
  }

  .contactDiv {
    align-items: center;
    text-align: center;
    justify-content: center;
    margin-left: 0;
  }

  .logoDiv {
    align-items: center;
    text-align: center;
    justify-content: center;
    margin-top: 2rem;
    align-self: center;

    .logo {
      width: 50%;
      margin-right: 0;
    }
  }

  .addressDiv {
    align-items: center;
    text-align: center;
    justify-content: center;
  }

  .languageDiv {
    align-items: center;
    text-align: center;
    justify-content: center;
    margin-right: 0;
  }
}

@media screen and (max-width: $smedium) {
  .footer {
    grid-template-columns: 0.5fr 1.5fr 1.5fr 0.5fr;
    grid-template-rows: 1fr 1fr 0.5fr 0.25fr 0.25fr;
    grid-template-areas:
      '. contactDiv addressDiv .'
      '. linksDiv linksDiv .'
      ". languageDiv languageDiv ."
      '. logoDiv logoDiv .'
      ' copyrightDiv copyrightDiv copyrightDiv copyrightDiv';

    .paragraph {
      font-size: 12px !important;
    }
  }

  .copyrightDiv {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .linksDiv {
    // align-items: center;
    text-align: center;
    margin-left: 0;
    padding-bottom: 0;
    margin-bottom: $vertical-padding/2;
  }

  .contactDiv {
    align-items: center;
    text-align: center;
    justify-content: center;
    margin-bottom: $vertical-padding;
    margin-left: 0;
  }

  .logoDiv {
    align-items: center;
    text-align: center;
    justify-content: flex-end;
  }

  .addressDiv {
    align-items: center;
    text-align: center;
    justify-content: center;
    margin-bottom: $vertical-padding;
  }

  .languageDiv {
    align-items: center;
    text-align: center;
    justify-content: center;
    margin-right: 0;
    margin-bottom: $vertical-padding/2;
  }
}