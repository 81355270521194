@import '../variables.scss';

.title {
  font-size: $huge-font;
  color: $white;
  font-family: $font-bold;
  line-height: 120%;
  margin: 0;

  &-smaller {
    font-size: $mbigger-font !important;
  }

  &-moreSmaller {
    font-size: $big-font !important;
  }

  &-medium {
    font-family: $font-medium !important;
  }

  &-centered {
    text-align: center;
  }
}

.subtitle {
  font-size: $mediuml-font;
  color: $secondary;
  font-family: $font;
  line-height: 150%;

  &-white {
    color: $white;
  }

  &-max {
    max-width: 1300px;
  }

  &-centered {
    text-align: center;
  }

  &-green {
    color: $green;
  }

  &-raleway {
    font-family: $font-RMedium;
  }

  &-bold {
    font-family: $font-bold;
  }

  &-semiBold {
    font-family: $font-semibold;
  }

  &-secondary {
    color: $secondary;
  }

  &-centered {
    text-align: center;
  }

  &-medium {
    font-size: $slarge-font;
  }

  &-large {
    font-size: $large-font;
  }
}

.paragraph {
  font-size: $medium-font;
  color: $secondary;
  font-family: $font;
  line-height: 150%;

  &-max {
    max-width: 1100px;
  }

  &-white {
    color: $white;
  }

  &-medium {
    font-family: $font-medium !important;
  }

  &-dark {
    color: $secondary;
  }

  &-margins {
    margin-bottom: 0.25rem;
    margin-top: 0.25rem;
  }

  &-smaller {
    font-size: $smaller-font !important;
  }

  &-centered {
    text-align: center;
  }

  &-bold {
    font-family: $font-bold;
  }

  &-link {
    &>a {
      margin-left: 0 !important;
      margin-right: 0 !important;
    }
  }
}

.error {
  margin-top: -20px;
  color: red;

  & a:link {
    color: red;
  }
}

@media screen and (max-width: $large) {
  .title {
    font-size: $bigger-font;

    &-smaller {
      font-size: $big-font !important;
    }

    &-moreSmaller {
      font-size: $large-font !important;
    }
  }

  .subtitle {
    font-size: $medium-font;
    line-height: 175% !important;

    &-large {
      font-size: $slarge-font;
    }
  }

  .paragraph {
    font-size: $smaller-font;
  }
}

@media screen and (max-width: $medium) {
  .title {
    font-size: $mbigger-font;

    &-smaller {
      font-size: $big-font !important;
    }

    &-moreSmaller {
      font-size: $large-font !important;
    }
  }

  .subtitle {
    font-size: $medium-font;

    &-large {
      font-size: $mediuml-font;
    }
  }
}


@media screen and (max-width: $lsmall) {
  .title {

    &-smaller {
      font-size: $slarge-font !important;
    }

    &-moreSmaller {
      font-size: $mediuml-font !important;
    }
  }

  .subtitle {
    font-size: $small-font;

    &-large {
      font-size: $medium-font;
    }
  }

}

@media screen and (max-width: $small) {
  .title {
    font-size: $large-font;

    &-smaller {
      font-size: $slarge-font !important;
    }

    &-moreSmaller {
      font-size: $mediuml-font !important;
    }
  }

  .subtitle {
    font-size: $small-font;

    &-large {
      font-size: $medium-font;
    }
  }

}