@import '../../components/variables.scss';

.references,
.referencePlinara {
    padding-top: $navbar-height;
    a{
        margin: inherit;
    }

    .container {
        display: flex;
        flex-direction: column;
        align-items: center;

        .title {
            margin-top: 3rem;
        }

        .paragraph {
            line-height: 200%;
        }

        .videoPlayer {
            width: 100% !important;
            height: 90vh !important;
            padding-top: 2rem;
            padding-bottom: 2rem;
        }

        .specifications {}

        .imageSwiper {
            margin-top: 2rem;
        }

        .phases {

            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 4rem;



            .phaseNumber {
                margin-top: 4rem;
                background-color: white;
                padding: 15px;
                border-radius: 50%;
                width: 60px;
                height: 60px;

                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                font-size: 36px;
                text-align: center;
                font-family: 'Roboto-bold';
                margin-bottom: 4rem;
            }
        }

        .twoItemsContainer {
            margin: 0;
            margin-top: 2rem;
            margin-bottom: 2rem;
            min-height: 400px;
            max-width: 1000px;
            padding: 0;

            .referencePreview {
                height: 100%;
                display: flex;
                flex-direction: column;

                img {
                    height: 270px;
                    // height: 100%;
                    object-fit: cover;
                    border-radius: 5px;
                    width: 100%;
                    transition: all 0.3s ease 0s;

                    &:hover {
                        transform: scale(1.05);
                        box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
                        cursor: pointer;
                    }
                }

                p {
                    text-align: left;
                }
            }
        }

        .referenceIntroText {
            margin-top: 4rem;
            margin-bottom: 2rem;
            display: flex;
            flex-direction: column;
            align-content: center;
            justify-content: center;
            align-items: flex-start;

            .subtitle {
                line-height: 200%;
            }
        }



        .referencesTypes {
            @extend .referenceIntroText;
            flex-direction: row;
            justify-content: space-evenly;
        }

        .swiperContainer {
            width: 100%;
            margin-bottom: 5rem;
            max-width: 1000px;
            // max-width: 1300px;
            display: flex;
            flex-direction: column;
            align-items: center;

            .button {
                margin-top: 3rem;
            }

            &>h3 {
                // margin-top: 2rem;
                // margin-bottom: 2rem;


                // &>.subtitle {
                //     &:hover {
                //         text-decoration: underline;
                //         transition: text-decoration 0.35s ease-in-out;
                //     }
                // }


                &>p {
                    text-align: left;
                    margin-left: auto;
                    margin-right: auto;
                    position: relative;
                    display: inline-block; // Ensure the underline matches the width of the text
                    transition: all 0.35s ease-in-out;

                    // Remove the text-decoration from the p element itself
                    text-decoration: none;

                    // Pseudo-element for the underline effect
                    &::after {
                        content: '';
                        position: absolute;
                        left: 0;
                        bottom: 0;
                        width: 0;
                        height: 2px;
                        /* Adjust height of the underline */
                        background-color: white;
                        /* Adjust underline color */
                        transition: width 0.35s ease-in-out;
                    }

                    &:hover {
                        cursor: pointer;

                        // On hover, extend the underline
                        &::after {
                            width: 100%;
                        }
                    }
                }

            }


            // .swiper {
            //     width: 100%;
            //     // max-width: 1000px;
            //     // height: 600px;

            //     .swiper-wrapper {
            //         width: 0;
            //     }

            //     .swiper-button-next,
            //     .swiper-button-prev {
            //         right: 10px;
            //         color: #D4D4D4 !important;
            //         fill: #D4D4D4 !important;
            //         stroke: #D4D4D4 !important;
            //     }

            //     .swiper-pagination-progressbar-fill {
            //         background-color: $green;
            //     }

            // }

            // .swiper-free-mode {
            //     height: inherit;
            //     margin-top: 10px;

            //     .swiper-wrapper {
            //         // width: inherit;
            //         margin: 0;
            //     }

            //     &>.swiper-slide {
            //         height: auto;

            //         // height: fit-content;
            //         :hover {
            //             cursor: pointer;
            //         }

            //         img {
            //             border-radius: 0;
            //         }
            //     }
            // }

            // .swiper-slide {
            //     text-align: center;
            //     font-size: 18px;
            //     background: $quaternary;
            //     // border-radius: 5px;
            //     height: auto;
            //     max-height: 600px;
            //     display: flex;
            //     justify-content: center;
            //     align-items: center;
            //     overflow: hidden;

            //     img {
            //         transition: all 0.3s ease 0s;
            //         width: 100%;
            //     }

            //     img:hover {
            //         transform: scale(1.25);
            //     }
            // }

            // .swiper-slide img {
            //     // display: block;
            //     // width: 100%;
            //     // height: 100%;
            //     // object-fit: cover;
            //     // position: absolute;
            //     // top: 50%;
            //     // left: 50%;
            //     // transform: translate(-50%, -50%);
            //     max-width: 100%;
            //     max-height: 100%;
            // }

            .projectDescription {
                margin-left: auto;
                display: flex;
                flex-wrap: wrap;
                margin-right: auto;
                flex-direction: column;
                align-content: flex-start;
                justify-content: center;
                align-items: flex-start;
                max-width: 1000px;
                width: 100%;


                &>.subtitle {

                    line-height: 180%;
                }
            }
        }
    }
    .videoPlayer{
        width: 100% !important;
        height: 80vh !important;
    }
}

@media screen and (min-width:$larger) {
    .references .container {
        .swiperContainer {
            max-width: 1400px;
        }

        .twoItemsContainer {
            max-width: 1400px;
            width: 100%;
            // min-height: 800px;
            .referencePreview{
                img {
                    height: 400px;
                }
            }
        }
    }

}