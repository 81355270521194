@import '../../components/variables.scss';

.mountingSystems {
    padding-top: $navbar-height;

    .title {
        margin-top: 3rem;
    }

    .solarText {
        margin-top: 4rem;
        display: flex;
        flex-direction: column;
        align-content: center;
        justify-content: center;
        align-items: center;

        .subtitle {
            line-height: 200%;
        }
    }

    .subtitle {
        margin-top: 3rem;
        margin-bottom: 3rem;
    }

    .systemsContainer {
        li {
            color: #a1a1a1;
        }

        .system {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: 1fr;
            grid-column-gap: 0px;
            grid-row-gap: 0px;
            margin-top: 3rem;
            margin-bottom: 3rem;

            .image {
                display: flex;
                grid-area: 1 / 1 / 2 / 2;
                justify-content: flex-start;
                flex-wrap: wrap;

                img {
                    margin: 0.5rem;
                    border-radius: 17px;
                    height: auto;
                    width: 100%;
                    max-width: 400px;
                }
            }


            .description {
                grid-area: 1/2/2/3;
                justify-items: flex-start;
                display: flex;
                margin: 0 1rem 1rem 1rem;
                flex-direction: column;
                align-items: flex-start;
                justify-content: flex-start;

                .titleDiv {
                    display: flex;
                }

                .subtitleDiv {
                    display: flex;
                }
            }


        }


    }

    @media screen and (max-width: $lmedium) {
        .systemsContainer {
            .subtitle {
                text-align: center;
            }

            .system {
                display: grid;
                grid-template-columns: 1fr;
                grid-template-rows: 1fr 0.85fr;
                margin-top: 0;
                margin-bottom: 0;

                .image {
                    display: flex;
                    grid-area: 1 / 1 / 2 / 2;
                    justify-content: center;
                    flex-wrap: wrap;

                    img {
                        margin: 0.5rem;
                        border-radius: 17px;
                        height: auto;
                        width: 100%;
                        max-width: 400px;
                    }
                }


                .description {
                    grid-area: 2 / 1 / 3 / 2;
                    justify-items: center;
                    display: flex;
                    margin: 0 1rem 1rem 1rem;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;

                    .titleDiv {
                        display: flex;
                    }

                    .subtitleDiv {
                        display: flex;
                    }
                }


            }


        }
    }
}