@import '../../components/variables.scss';

.software {
  padding-top: $navbar-height;

  .title {
    margin-top: $horizontal-padding;
    margin-bottom: $horizontal-padding;
  }
  .software-image {
    margin-top: 3rem;
    margin-bottom: 3rem;
    width: 100%;
    border-radius: 17px;
    margin-left: auto;
    margin-right: auto;
    display: block;
    min-width: 200px;
    max-width: 800px;
  }
  .paragraph {
    line-height: 200%;
    // font-size: $mediuml-font;
  }
  .landingSection {
    .subtitle {
      line-height: 200%;
    }
  }
  @media screen and (max-width: $medium) {
    .subtitle {
      text-align: center;
    }
  }
}
