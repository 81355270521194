@import '../../components/variables.scss';

.search {
    padding-top: $navbar-height;
    width: 100%;

    .searchBar {
        display: none;

    }

    .results {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: 1fr;
        grid-column-gap: 2rem;
        grid-row-gap: 0px;

        .loading-text {
            font-size: 1.5rem;
            font-weight: bold;
            animation: pulse 1.5s infinite;
        }

        @keyframes pulse {

            0%,
            100% {
                opacity: 1;
            }

            50% {
                opacity: 0.5;
            }
        }


        // .categories {
        //     grid-area: 1 / 1 / 2 / 2;
        //     display: flex;
        //     flex-direction: column;
        //     background-color: $tertiary;
        //     border-radius: 17px;
        //     height: 200px;
        //     padding: 22px 30px;
        //     margin-bottom: 2rem;
        //     height: fit-content;

        //     .button {
        //         margin: 1rem;
        //     }

        //     // .subtitle {
        //     //     margin: 0;
        //     //     margin-bottom: 20px;
        //     // }

        //     // a {
        //     //     color: $secondary;
        //     // }
        // }

        .products {
            grid-area: 1 / 1 / 2 / 5;

            .searchPageResult {
                display: flex;
                flex-direction: row;
                // background-color: $tertiary;
                border-radius: 17px;
                min-height: 130px;
                // max-height: 200px;
                padding-top: 2rem;
                padding-bottom: 2rem;
                border-radius: 0;
                border-bottom: 1px solid $quaternary;




                a,
                button {
                    margin: 0;
                }

                .imgDiv {
                    margin-right: 2rem;
                    display: flex;
                    align-items: center;

                    .noImgBg {
                        background-color: $grey;
                        max-width: 390px;
                        max-height: 390px;
                        height: 100%;
                        z-index: 10;
                    }

                    .imgBackground {
                        border-radius: 10px;
                        width: 150px;
                        height: 100%;
                        background-color: white;
                        overflow: hidden;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;

                        img {
                            width: 100%;
                            border-radius: 10px;
                            //   max-width: 390px;
                            object-fit: cover;
                            z-index: 9;
                            max-width: 390px;
                            // height: 100%;
                            // height: 260px;
                            position: relative;

                            &:hover {
                                cursor: pointer;
                            }
                        }
                    }

                    .placeholderBg {
                        background-color: #949494;
                    }
                }

                .detailsDiv {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;

                    .productBrand {
                        a {
                            color: $green;
                            font-family: 'Roboto-medium';

                            &:hover {
                                text-decoration: underline;
                            }
                        }
                    }

                    .productDetails {





                        .productTitle {
                            color: $grey;
                            word-break: break-word;

                            .subtitle {
                                margin: 0;

                                &:hover {
                                    cursor: pointer;
                                }

                            }

                            .ref {
                                margin-top: 0;
                                margin-bottom: 1rem;
                                font-size: 14px;
                            }
                        }



                        .productDescription {
                            margin-top: 1rem;
                            text-overflow: ellipsis;
                            word-break: keep-all;
                        }
                    }

                    .productButtons {

                        .buttons {
                            display: flex;
                            margin-top: 1rem;

                        }
                    }
                }

            }

            .searchPageResult:first-of-type {
                padding-top: 0 !important;
            }

            .searchPageResultdiv:last-of-type {
                border-bottom: 0 !important
            }





            .pagination {
                display: flex;
                gap: 0.5rem;
                justify-content: center;
                margin-top: 1rem;
                overflow-x: scroll;
            }

            .pagination button {
                padding: 0.5rem 1rem;
                // border: 1px solid #ccc;
                // background-color: white;
                cursor: pointer;
            }

            .pagination button.active {
                background-color: $green;
                color: white;
            }

            .pagination button:disabled {
                cursor: not-allowed;
                opacity: 0.5;
            }

        }
    }
}

@media screen and (max-width: 768px) {
    .search {
        padding-top: 6rem;

        .container {
            .searchBarContainer {
                // width: 90vw;
                display: flex;
                flex-direction: row;
                justify-content: center;


                .searchBar {
                    width: 100%;
                    display: inherit;

                    .input-wrapper {
                        width: 100%;

                        .results-list {
                            display: none;
                        }
                    }
                }
            }

            &>h1 {
                &>p {
                    font-size: 19px !important;
                }
            }


            .results {
                .products {

                    .searchPageResult {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                    
                        .imgDiv {
                            margin-right: 1rem;
                            .imgBackground{
                                margin-bottom: 2rem;

                            }
                            
                        }
                    }

                    .results-categories-content {
                        display: flex;
                        flex-direction: row;
                        align-content: center;
                        align-items: center;
                        flex-wrap: nowrap;
                        overflow-x: scroll;

                        .button {
                            margin: 0;
                            margin-bottom: 1rem;
                            margin-right: 1rem;
                            padding: 0.75rem 1rem;

                            &>a {
                                margin: 0;
                            }
                        }
                    }
                }
            }
        }
    }
}